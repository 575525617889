import { BreadcrumbNav } from "components/molecules";
import React, { useEffect, useState } from "react";
import {
  CaseFromOptions,
  JobsListOptions,
  PageMode,
  Source,
  StandarMedicationReasonOptions,
  SymthomaticOptions,
} from "utils/constant";
import FormStepIndicatorComponent from "./FormStepIndicator";
import PersonalDataStepForm from "./FormWizard/PersonalData";
import DiscoveryStatusStepForm from "./FormWizard/DiscoveryStatus";
import TreatmentStepForm from "./FormWizard/Treatment";
import {
  errorToastNotif,
  getDataFromLocalStorage,
  nullConverter,
} from "utils/common";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import RegmalFirst from "services/RegmalFirst";
import RegmalReference from "services/RegmalReference";
import { toast } from "react-toastify";
import Wilayah from "services/Wilayah";
import Fasyankes from "services/Fasyankes";
import UKBM from "services/UKBM";
import RegmalSecond from "services/RegmalSecond";
import PeCases from "services/PeKasus";
import Kader from "services/Kader";
import medicationStatus from "./StandarMedication";

const RegmalFirstService = new RegmalFirst();
const RegmalReferenceService = new RegmalReference();
const WilayahService = new Wilayah();
const FasyankesService = new Fasyankes();
const UKBMService = new UKBM();
const RegmalSecondService = new RegmalSecond();
const PeKasusService = new PeCases();
const KaderService = new Kader();

const FormComponent = ({ changeMode, pageMode }) => {
  const [formStep, setFormStep] = useState(1);

  const [addressState, setAddressState] = useState({
    provincies: [],
    districts: [],
    subdistricts: [],
    urbanvillages: [],
    fasyankes: [],
  });

  const [domicileState, setDomicileState] = useState({
    provincies: [],
    districts: [],
    subdistricts: [],
    fasyankes: [],
  });

  const [referenceState, setReferenceState] = useState({
    provincies: [],
    districts: [],
    fasyankes: [],
  });

  const [cadresData, setCadresData] = useState([]);
  const [surveyContactCases, setSurveyContactCases] = useState([]);
  const [srData, setSrData] = useState([]);
  const [ssrData, setSsrData] = useState([]);
  const [mbsDates, setMbsDates] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // step 1 - personal data
      is_foreign_nationality: "Tidak",
      not_have_ktp: "Tidak",
      ktp: "",
      name: "",
      age: "",
      age_type: "",
      gender: "",
      oap: "",
      pregnancy: "",
      province_id: currentUser?.province_id,
      district_id: "",
      subdistrict_id: "",
      fasyankes_id: "",
      urbanvillage_id: "",
      sub_village: "",
      address: "",
      is_domicile_outside: "",
      domicile_province_id: "",
      domicile_district_id: "",
      domicile_subdistrict_id: "",
      domicile_fasyankes_id: "",
      // step 2 - discovery status
      case_from: "",
      other_case_from: "",
      discovery_activities: "",
      mbs_date: "",
      survey_contact_date: "",
      first_case_trigger_survey_contact: "",
      sr_id: "",
      ssr_id: "",
      house_visit: "",
      cadres_id: [],
      lab_checking_type: "",
      total_rdt: "",
      reg_number: "",
      slide_code: "",
      patient_visite_date: "",
      lab_checking_date: "",
      parasyte: [],
      isCadre: "",
      // step 3 - treatment
      job: "",
      other_job: "",
      disease_degree: "",
      body_temperature: "",
      sympthomatic: "",
      other_sympthomatic: "",
      sympthomatic_date: "",
      gametosit: "",
      parasyte_density: "",
      weight: "",
      recurrence: "",
      anemia_status: "",
      medication_date: "",
      medication: "",
      dhp_tablet: "",
      primaquin_tablet: "",
      kina_tablet: "",
      artesunat_injeksi: "",
      kina_injeksi: "",
      doksisiklin: "",
      tetrasiklin: "",
      klindamisin: "",
      standard_medication_status: "",
      not_standard_medication_reason: "",
      other_not_standard_medication_reason: "",
      treatment: "",
      death_with_malaria: "",
      referred_to_other_faskes: "",
      ref_reason: "",
      ref_date: "",
      ref_province_id: "",
      ref_district_id: "",
      ref_fasyankes_id: "",
    },
    validate: (values) => {
      const errors = {};

      switch (formStep) {
        case 1:
          if (values.not_have_ktp === "Tidak") {
            if (!values.ktp) {
              errors.ktp = "NIK/No KTP wajib diisi";
            } else if (values.ktp.length > 16) {
              errors.ktp = "NIK/No KTP maksimal 16 karakter";
            } else if (values.ktp.length < 16) {
              errors.ktp = "NIK/No KTP harus 16 digit";
            }
          }

          if (!values.name) {
            errors.name = "Nama wajib diisi";
          }

          // if (!values.age_type) {
          //   errors.age_type = "Satuan wajib dipilih";
          // } else {
          //   if (values.age >= 12) {
          //     errors.age_type = "Usia harus tahun";
          //   }
          // }

          if (!values.age) {
            errors.age = "Usia wajib diisi";
          } else if (/^0\d+/.test(values.age)) {
            errors.age = "Usia tidak boleh diawali dengan angka 0";
          } else if (parseInt(values.age) === 0) {
            errors.age = "Usia tidak boleh 0";
          }

          if (!values.gender) {
            errors.gender = "Jenis kelamin wajib diisi";
          }

          if (values.gender === "Perempuan" && values.age > 10) {
            if (!values.pregnancy) {
              errors.pregnancy = "Status kehamilan wajib diisi";
            }
          }

          if (!values.oap) {
            errors.oap = "Status Kependudukan (Khusus Papua)";
          }

          if (!values.province_id) {
            errors.province_id = "Alamat Provinsi wajib diisi";
          }
          if (!values.district_id) {
            errors.district_id = "Alamat Kabupaten/Kota wajib diisi";
          }

          if (currentUser?.fasyankes_type_id === "jnsfas-01") {
            if (!values.subdistrict_id) {
              errors.subdistrict_id = "Alamat Kecamatan wajib diisi";
            }
          }

          if (!values.fasyankes_id) {
            errors.fasyankes_id = "Alamat Fasyankes wajib diisi";
          }

          if (currentUser?.fasyankes_type_id === "jnsfas-01") {
            if (!values.urbanvillage_id) {
              errors.urbanvillage_id = "Alamat Desa/Kelurahan wajib diisi";
            }
          }

          if (!values.sub_village) {
            errors.sub_village = "Nama dusun wajib diisi";
          }

          if (!values.address) {
            errors.address = "Alamat lengkap wajib diisi";
          }

          if (!values.is_domicile_outside) {
            errors.is_domicile_outside =
              "Apakah Pasien Beralamat Diluar Wilayah wajib diisi";
          }

          if (values.is_domicile_outside === "Ya") {
            if (!values.domicile_province_id) {
              errors.domicile_province_id = "Provinsi Domisili wajib diisi";
            }
            if (!values.domicile_district_id) {
              errors.domicile_district_id =
                "Kabupaten/Kota Domisili wajib diisi";
            }
          }
          break;
        case 2:
          if (currentUser?.fasyankes_type_id === "jnsfas-01") {
            if (!values.case_from) {
              errors.case_from = "Asal Penemuan wajib diisi";
            }

            if (values.case_from === "Lainnya") {
              if (!values.other_case_from) {
                errors.other_case_from = "Asal Penemuan lainnya wajib diisi";
              }
            }
          }

          if (!values.discovery_activities) {
            errors.discovery_activities = "Kegiatan Penemuan wajib diisi";
          }

          if (values.discovery_activities === "FUP") {
            if (!values.house_visit) {
              errors.house_visit = "Pilih Petugas";
            }
            if (values.house_visit === "Kader") {
              if (values.isCadre === "Ya") {
                if (!values.cadres_id) {
                  errors.cadres_id = "Kader wajib diisi";
                }
              }
            }
          }

          if (values.discovery_activities === "MBS") {
            if (!values.mbs_date) {
              errors.mbs_date = "Tanggal MBS Wajib diisi";
            }
            if (!values.house_visit) {
              errors.house_visit = "Pilih Petugas";
            }

            if (values.house_visit === "Kader") {
              if (values.isCadre === "Ya") {
                if (!values.cadres_id) {
                  errors.cadres_id = "Kader wajib diisi";
                }
              }
            }
          }

          if (values.discovery_activities === "Survei Kontak") {
            if (!values.survey_contact_date) {
              errors.survey_contact_date = "Tanggal Survey Kontak Wajib diisi";
            }
            if (!values.house_visit) {
              errors.house_visit = "Pilih Petugas";
            }
            if (values.house_visit === "Kader") {
              if (values.isCadre === "Ya") {
                if (!values.cadres_id) {
                  errors.cadres_id = "Kader wajib diisi";
                }
              }
            }
          }

          if (values.discovery_activities === "Survei Non PE") {
            if (!values.house_visit) {
              errors.house_visit = "Pilih Petugas";
            }
            if (values.house_visit === "Kader") {
              if (values.isCadre === "Ya") {
                if (!values.cadres_id) {
                  errors.cadres_id = "Kader wajib diisi";
                }
              }
            }
          }

          if (values.discovery_activities === "Kunjungan Rumah") {
            if (!values.house_visit) {
              errors.house_visit = "Pilih Petugas";
            }
            if (values.house_visit === "Kader") {
              if (values.isCadre === "Ya") {
                if (!values.cadres_id) {
                  errors.cadres_id = "Kader wajib diisi";
                }
              }
            }
          }

          if (values.discovery_activities === "Surveilans Migrasi") {
            if (!values.house_visit) {
              errors.house_visit = "Pilih Petugas";
            }
            if (values.house_visit === "Kader") {
              if (values.isCadre === "Ya") {
                if (!values.cadres_id) {
                  errors.cadres_id = "Kader wajib diisi";
                }
              }
            }
          }
          if (values.discovery_activities === "Posyandu") {
            if (!values.house_visit) {
              errors.house_visit = "Pilih Petugas";
            }
            if (values.house_visit === "Kader") {
              if (values.isCadre === "Ya") {
                if (!values.cadres_id) {
                  errors.cadres_id = "Kader wajib diisi";
                }
              }
            }
          }

          if (!values.lab_checking_type) {
            errors.lab_checking_type = "Jenis Pemeriksaan wajib diisi";
          }

          if (values.lab_checking_type !== "Tanpa Pemeriksaan") {
            if (!values.total_rdt && parseInt(values.total_rdt) !== 0) {
              errors.total_rdt = "Jumlah RDT wajib diisi";
            }

            if (!values.patient_visite_date) {
              errors.patient_visite_date =
                "Tanggal kunjungan pasien wajib diisi";
            }

            if (!values.lab_checking_date) {
              errors.lab_checking_date = "Tanggal pemeriksaan lab wajib diisi";
            }

            if (!values.parasyte || values.parasyte.length === 0) {
              errors.parasyte = "Parasit wajib dipilih setidaknya 1";
            }
          }

          if (values.isCadre === "Ya") {
            if (values.cadres_id?.length === 0 || !values.cadres_id) {
              errors.cadres_id = "Mohon Pilih Kader";
            }
          }
          break;
        case 3:
          if (values.job === "Lainnya") {
            if (!values.other_job) {
              errors.other_job = "Pekerjaan lainnya wajib diisi";
            }
          }

          if (!values.disease_degree) {
            errors.disease_degree = "Derajat Penyakit Malaria wajib diisi";
          }

          if (values.sympthomatic === "Lainnya") {
            if (!values.sympthomatic_date) {
              errors.sympthomatic_date = "Tanggal gejala wajib diisi";
            }
            if (!values.other_sympthomatic) {
              errors.other_sympthomatic = "Gejala lainnya wajib diisi";
            }
          }

          if (
            values.sympthomatic !== "Tidak bergejala" &&
            values.sympthomatic
          ) {
            if (!values.sympthomatic_date) {
              errors.sympthomatic_date = "Tanggal gejala wajib diisi";
            }
          }

          if (!values.weight) {
            errors.weight = "Berat badan wajib diisi";
          }

          if (!values.recurrence) {
            errors.recurrence = "Kekambuhan wajib diisi";
          }

          const regex = /^\d*\.?\d*$/;
          if (values.dhp_tablet) {
            if (
              !(
                regex.test(values.dhp_tablet) &&
                !values.dhp_tablet?.startsWith(".") &&
                !values.dhp_tablet?.startsWith(",")
              )
            ) {
              errors.dhp_tablet = "Hanya menerima inputan desimal yang valid";
            }
          }
          if (values.primaquin_tablet) {
            if (
              !(
                regex.test(values.primaquin_tablet) &&
                !values.primaquin_tablet?.startsWith(".") &&
                !values.primaquin_tablet?.startsWith(",")
              )
            ) {
              errors.primaquin_tablet =
                "Hanya menerima inputan desimal yang valid";
            }
          }
          if (values.artesunat_injeksi) {
            if (
              !(
                regex.test(values.artesunat_injeksi) &&
                !values.artesunat_injeksi?.startsWith(".") &&
                !values.artesunat_injeksi?.startsWith(",")
              )
            ) {
              errors.artesunat_injeksi =
                "Hanya menerima inputan desimal yang valid";
            }
          }
          if (values.kina_tablet) {
            if (
              !(
                regex.test(values.kina_tablet) &&
                !values.kina_tablet?.startsWith(".") &&
                !values.kina_tablet?.startsWith(",")
              )
            ) {
              errors.kina_tablet = "Hanya menerima inputan desimal yang valid";
            }
          }
          if (values.kina_injeksi) {
            if (
              !(
                regex.test(values.kina_injeksi) &&
                !values.kina_injeksi?.startsWith(".") &&
                !values.kina_injeksi?.startsWith(",")
              )
            ) {
              errors.kina_injeksi = "Hanya menerima inputan desimal yang valid";
            }
          }
          if (values.doksisiklin) {
            if (
              !(
                regex.test(values.doksisiklin) &&
                !values.doksisiklin?.startsWith(".") &&
                !values.doksisiklin?.startsWith(",")
              )
            ) {
              errors.doksisiklin = "Hanya menerima inputan desimal yang valid";
            }
          }
          if (values.tetrasiklin) {
            if (
              !(
                regex.test(values.tetrasiklin) &&
                !values.tetrasiklin?.startsWith(".") &&
                !values.tetrasiklin?.startsWith(",")
              )
            ) {
              errors.tetrasiklin = "Hanya menerima inputan desimal yang valid";
            }
          }
          if (values.klindamisin) {
            if (
              !(
                regex.test(values.klindamisin) &&
                !values.klindamisin?.startsWith(".") &&
                !values.klindamisin?.startsWith(",")
              )
            ) {
              errors.klindamisin = "Hanya menerima inputan desimal yang valid";
            }
          }

          if (values.not_standard_medication_reason === "Lainnya") {
            if (!values.other_not_standard_medication_reason) {
              errors.other_not_standard_medication_reason =
                "Alasan lainnya wajib diisi";
            }
          }

          if (!values.treatment) {
            errors.treatment = "Perawatan wajib diisi";
          }

          if (!values.death_with_malaria) {
            errors.death_with_malaria = "Kematian dengan malaria wajib diisi";
          }

          if (!values.referred_to_other_faskes) {
            errors.referred_to_other_faskes =
              "Status rujukan ke faskes lain wajib diisi";
          }

          if (values.referred_to_other_faskes === "Ya") {
            if (!values.ref_reason) {
              errors.ref_reason = "Alasan dirujuk wajib diisi";
            }
            if (!values.ref_date) {
              errors.ref_date = "Tanggal dirujuk wajib diisi";
            }
            if (!values.ref_province_id) {
              errors.ref_province_id = "Provinsi rujukan wajib diisi";
            }
            if (!values.ref_district_id) {
              errors.ref_district_id = "Kab/Kota rujukan wajib diisi";
            }
            if (!values.ref_fasyankes_id) {
              errors.ref_fasyankes_id = "Fasyankes rujukan wajib diisi";
            }
          }
          break;
        default:
          break;
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (formStep === 3) {
        let medicationData = {
          dhp_tablet: nullConverter(values.dhp_tablet),
          primaquin_tablet: nullConverter(values.primaquin_tablet),
          kina_tablet: nullConverter(values.kina_tablet),
          artesunat_injeksi: nullConverter(values.artesunat_injeksi),
          kina_injeksi: nullConverter(values.kina_injeksi),
          doksisiklin: nullConverter(values.doksisiklin),
          tetrasiklin: nullConverter(values.tetrasiklin),
          klindamisin: nullConverter(values.klindamisin),
        };

        let payload = {
          // step 1 - personal data
          is_foreign_nationality: values.is_foreign_nationality,
          not_have_ktp: values.not_have_ktp,
          ktp: values.ktp,
          name: values.name,
          age: values.age,
          age_type: values.age_type,
          gender: values.gender,
          oap: values.oap,
          pregnancy: nullConverter(values.pregnancy),
          province_id: values.province_id,
          district_id: values.district_id,
          subdistrict_id: values.subdistrict_id,
          fasyankes_id: values.fasyankes_id,
          fasyankes_origin_id: currentUser?.fasyankes_id,
          urbanvillage_id: values.urbanvillage_id,
          sub_village: values.sub_village,
          address: values.address,
          is_domicile_outside: values.is_domicile_outside,
          domicile_province_id: values.domicile_province_id,
          domicile_district_id: values.domicile_district_id,
          domicile_subdistrict_id: values.domicile_subdistrict_id,
          domicile_fasyankes_id: values.domicile_fasyankes_id,
          // step 2 - discovery status
          case_from:
            values.case_from === "Lainnya"
              ? `Lainnya_${values.other_case_from}`
              : nullConverter(values.case_from),
          discovery_activities: nullConverter(values.discovery_activities),
          mbs_date: nullConverter(values.mbs_date),
          survey_contact_date: nullConverter(values.survey_contact_date),
          first_case_trigger_survey_contact: nullConverter(
            values.first_case_trigger_survey_contact
          ),
          sr_id: nullConverter(values.sr_id),
          ssr_id: nullConverter(values.ssr_id),
          house_visit: nullConverter(values.house_visit),
          cadres_id:
            values.cadres_id && values.cadres_id.map((item) => item.value),
          lab_checking_type: nullConverter(values.lab_checking_type),
          total_rdt: nullConverter(values.total_rdt),
          reg_number: nullConverter(values.reg_number),
          slide_code: nullConverter(values.slide_code),
          patient_visite_date: nullConverter(values.patient_visite_date),
          lab_checking_date: nullConverter(values.lab_checking_date),
          parasyte:
            values.parasyte.length === 0
              ? nullConverter(values.parasyte)
              : values.parasyte,
          // step 3 - treatment
          job:
            values.job === "Lainnya"
              ? `Lainnya_${values.job}`
              : nullConverter(values.job),
          disease_degree: values.disease_degree,
          body_temperature: nullConverter(values.body_temperature),
          sympthomatic:
            values.sympthomatic === "Lainnya"
              ? `Lainnya_${values.other_sympthomatic}`
              : nullConverter(values.sympthomatic),
          sympthomatic_date: nullConverter(values.sympthomatic_date),
          gametosit: nullConverter(values.gametosit),
          parasyte_density: nullConverter(values.parasyte_density),
          weight: values.weight,
          recurrence: values.recurrence,
          anemia_status: nullConverter(values.anemia_status),
          medication_date: nullConverter(values.medication_date),
          medication: medicationData,
          dhp_tablet: nullConverter(values.dhp_tablet),
          primaquin_tablet: nullConverter(values.primaquin_tablet),
          kina_tablet: nullConverter(values.kina_tablet),
          artesunat_injeksi: nullConverter(values.artesunat_injeksi),
          kina_injeksi: nullConverter(values.kina_injeksi),
          doksisiklin: nullConverter(values.doksisiklin),
          tetrasiklin: nullConverter(values.tetrasiklin),
          klindamisin: nullConverter(values.klindamisin),
          standard_medication_status: values.standard_medication_status,
          not_standard_medication_reason:
            values.not_standard_medication_reason === "Lainnya"
              ? `Lainnya_${values.other_not_standard_medication_reason}`
              : nullConverter(values.not_standard_medication_reason),
          treatment: values.treatment,
          death_with_malaria: values.death_with_malaria,
          referred_to_other_faskes: values.referred_to_other_faskes,
          ref_reason: nullConverter(values.ref_reason),
          ref_date: nullConverter(values.ref_date),
          ref_province_id: nullConverter(values.ref_province_id),
          ref_district_id: nullConverter(values.ref_district_id),
          ref_fasyankes_id: nullConverter(values.ref_fasyankes_id),
        };

        let rujukanPayload = {
          is_foreign_nationality: payload.is_foreign_nationality,
          not_have_ktp: payload.not_have_ktp,
          ktp: payload.ktp,
          name: payload.name,
          age: payload.age,
          age_type: payload.age_type,
          gender: payload.gender,
          source_fasyankes_id: currentUser?.fasyankes_id,
          ref_fasyankes_id: payload.ref_fasyankes_id,
          ref_date: payload.ref_date,
          ref_patient_arrival_date: null,
          is_lab_rechecking: null,
          lab_rechecking_type: null,
          lab_rechecking_date: null,
          parasyte:
            values.parasyte.length === 0
              ? nullConverter(values.parasyte)
              : values.parasyte,
          disease_degree: payload.disease_degree,
          body_temperature: payload.body_temperature,
          sympthomatic: payload.sympthomatic,
          sympthomatic_date: payload.sympthomatic_date,
          gametosit: payload.gametosit,
          parasyte_density: payload.parasyte_density,
          weight: payload.weight,
          medication_date: payload.medication_date,
          medication: payload.medication,
          standard_medication_status: values.standard_medication_status,
          treatment: payload.treatment,
          death_with_malaria: payload.death_with_malaria,
          status: "Belum",
          recurrence: payload.recurrence,
        };

        switch (pageMode) {
          case PageMode.add:
            await RegmalFirstService.add(payload)
              .then(async (res) => {
                if (res.data.data.referred_to_other_faskes === "Ya") {
                  let payload = {
                    regmal_id: res.data.data.id,
                    ...rujukanPayload,
                  };
                  await RegmalReferenceService.add(payload)
                    .then((res) => {
                      changeMode(PageMode.view);
                    })
                    .catch((err) => {
                      console.log(err);

                      if (err.response.status === 400) {
                        toast.error(
                          "User anda belum terverifikasi pada tahun ini, silahkan hubungi administrator",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                          }
                        );
                      } else {
                        errorToastNotif(err);
                      }
                    });
                } else {
                  changeMode(PageMode.view);
                }

                toast.success("Data Register Malaria 1 Berhasil ditambahkan", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
            break;
          case PageMode.edit:
            await RegmalFirstService.update(payload, selectedItem?.id)
              .then(async (res) => {
                if (
                  selectedItem?.referred_to_other_faskes === "Ya" &&
                  payload.referred_to_other_faskes === "Ya"
                ) {
                  let payload = {
                    regmal_id: selectedItem?.id,
                    ...rujukanPayload,
                  };

                  await RegmalReferenceService.update(
                    payload,
                    selectedItem?.regmal_reference.id
                  )
                    .then((res) => {
                      changeMode(PageMode.view);
                    })
                    .catch((err) => {
                      console.log(err);
                      errorToastNotif(err);
                    });
                } else if (payload.referred_to_other_faskes === "Ya") {
                  let payload = {
                    regmal_id: selectedItem?.id,
                    ...rujukanPayload,
                  };
                  await RegmalReferenceService.add(payload)
                    .then((res) => {
                      changeMode(PageMode.view);
                    })
                    .catch((err) => {
                      console.log(err);
                      errorToastNotif(err);
                    });
                }
                changeMode(PageMode.view);
                toast.success("Data Register Malaria 1 Berhasil Diupdate", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
            break;
          default:
            return null;
        }
      }
    },
  });

  const handleNextStep = () => {
    formik.submitForm();
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        formik.setTouched({});
        setFormStep(formStep + 1);
      } else {
        toast.error("Mohon lengkapi field yang masih required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  // Fetch wilayah and faskes for address
  const fetchAddressProvincies = async () => {
    try {
      const response = await WilayahService.getAll(Source.PROVINCE, {
        page: 1,
        limit: 9999,
      });

      const data = response.province.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setAddressState((prevState) => ({
        ...prevState,
        provincies: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressDistrictByProvince = async (provinceId) => {
    try {
      const response = await WilayahService.getAll(Source.DISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: `province_id`,
        searchValue: provinceId?.split("|")[0],
      });

      const data = response.district.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setAddressState((prevState) => ({
        ...prevState,
        districts: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressSubdistrictsByDistrict = async (districtId) => {
    try {
      const response = await WilayahService.getAll(Source.SUBDISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: `district_id`,
        searchValue: districtId?.split("|")[0],
      });

      const data = response.subdistrict.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setAddressState((prevState) => ({
        ...prevState,
        subdistricts: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressUrbanvillagesBySubdistrict = async (subdistrictId) => {
    try {
      const response = await WilayahService.getAll(Source.URBANVILLAGE, {
        page: 1,
        limit: 9999,
        searchBy: `subdistrict_id`,
        searchValue: subdistrictId?.split("|")[0],
      });

      const data = response.urbanvillage.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setAddressState((prevState) => ({
        ...prevState,
        urbanvillages: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressFasyankesByDistrict = async (districtId) => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        page: 1,
        limit: 9999,
        searchBy: `district_id`,
        searchValue: districtId?.split("|")[0],
        excludePkm: false,
      });

      const data = response.fasyankes.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setAddressState((prevState) => ({
        ...prevState,
        fasyankes: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressFasyankesBySubdistrict = async (subdistrictId) => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        page: 1,
        limit: 9999,
        searchBy: `subdistrict_id`,
        searchValue: subdistrictId?.split("|")[0],
        excludePkm: false,
      });

      const data = response.fasyankes.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setAddressState((prevState) => ({
        ...prevState,
        fasyankes: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch wilayah and faskes for domicile
  const fetchDomicileProvincies = async () => {
    try {
      const response = await WilayahService.getAll(Source.PROVINCE, {
        page: 1,
        limit: 9999,
      });

      const data = response.province.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setDomicileState((prevState) => ({
        ...prevState,
        provincies: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDomicileDistrictsByProvince = async (provinceId) => {
    try {
      const response = await WilayahService.getAll(Source.DISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: `province_id`,
        searchValue: provinceId?.split("|")[0],
      });

      const data = response.district.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setDomicileState((prevState) => ({
        ...prevState,
        districts: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDomicileSubdistrictsByDistrict = async (districtId) => {
    try {
      const response = await WilayahService.getAll(Source.SUBDISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: `district_id`,
        searchValue: districtId?.split("|")[0],
      });

      const data = response.subdistrict.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setDomicileState((prevState) => ({
        ...prevState,
        subdistricts: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDomicileFasyankesBySubdistrict = async (subdistrictId) => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        page: 1,
        limit: 9999,
        searchBy: `subdistrict_id`,
        searchValue: subdistrictId?.split("|")[0],
      });

      const data = response.fasyankes
        .filter((item) => item.id !== currentUser?.fasyankes_id?.split("|")[0])
        .map((item) => ({
          label: item.name,
          value: `${item.id}|${item.name}`,
        }));

      setDomicileState((prevState) => ({
        ...prevState,
        fasyankes: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch wilayah and faskes for reference case
  const fetchReferenceProvincies = async () => {
    try {
      const response = await WilayahService.getAll(Source.PROVINCE, {
        page: 1,
        limit: 9999,
      });

      const data = response.province.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setReferenceState((prevState) => ({
        ...prevState,
        provincies: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReferenceDistrictsByProvince = async (provinceId) => {
    try {
      const response = await WilayahService.getAll(Source.DISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: `province_id`,
        searchValue: provinceId?.split("|")[0],
      });
      const data = response.district.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setReferenceState((prevState) => ({
        ...prevState,
        districts: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReferenceFasyankesByDistrict = async (districtId) => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        page: 1,
        limit: 9999,
        searchBy: `district_id`,
        searchValue: districtId?.split("|")[0],
        excludePkm: false,
      });

      const data = response.fasyankes
        .filter((item) => item.id !== currentUser?.fasyankes_id?.split("|")[0])
        .map((item) => ({
          label: item.name,
          value: `${item.id}|${item.name}`,
        }));

      setReferenceState((prevState) => ({
        ...prevState,
        fasyankes: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllSrData = async () => {
    try {
      const response = await UKBMService.getAllSR("sr", "", "");

      const data = response.sr.map((item) => ({
        label: item.sr_name,
        value: item.id,
      }));

      setSrData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllSsrDataBySr = async (srId) => {
    try {
      const response = await UKBMService.getSSRbySR("ssr", "sr_id", srId);

      const data = response.ssr.map((item) => ({
        label: item.ssr_name,
        value: item.id,
      }));

      setSsrData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMbsDates = async () => {
    try {
      const response = await RegmalSecondService.getAll({
        page: 1,
        limit: 9999,
        searchBy: "fasyankes_id",
        searchValue: currentUser?.fasyankes_id,
      });

      const data = response.second_regmals
        .map((item) => {
          if (item.mbs_date !== null) {
            return {
              label: item.mbs_date,
              value: item.mbs_date,
            };
          }
        })
        .filter(Boolean);

      setMbsDates(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSurveyContacts = async (value) => {
    try {
      const response = await PeKasusService.getAll({
        searchBy: `fasyankes_id,contact_survey_date|YYYY-MM-DD`,
        searchValue: `${currentUser?.fasyankes_id},${value}`,
        includeRegmal: true,
      });

      const data = response.pe_cases.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      setSurveyContactCases(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllCadresData = async () => {
    try {
      const response = await KaderService.getAll({
        page: 1,
        limit: 9999,
        searchBy: `fasyankes_id`,
        searchValue: currentUser?.fasyankes_id,
      });

      const data = response.cadres.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setCadresData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const generateSlideCode = () => {
    let slideCode = "";

    const districtId = formik.values.district_id?.split("|")[0];
    const fasyankesId = formik.values.fasyankes_id?.split("|")[0];
    const regNumber = formik.values.reg_number;
    const monthLabChecking =
      formik.values.lab_checking_date?.split("-")[1] || 0;
    const yearLabChecking = formik.values.lab_checking_date?.split("-")[0] || 0;

    slideCode = `${districtId}/${fasyankesId}/${regNumber}/${monthLabChecking}/${yearLabChecking}`;

    formik.setFieldValue("slide_code", slideCode);
  };

  const generateStatusMedication = () => {
    let statusMedication = medicationStatus(formik.values);

    if (statusMedication == "Standard") {
      formik.setFieldValue("standard_medication_status", "Standard");
      formik.setFieldValue("not_standard_medication_reason", "");
      formik.setFieldValue("other_not_standard_medication_reason", "");
    } else {
      formik.setFieldValue("standard_medication_status", "Tidak Standard");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    generateStatusMedication();
  }, [
    formik.values.disease_degree,
    formik.values.weight,
    formik.values.age,
    formik.values.age_type,
    formik.values.gender,
    formik.values.pregnancy,
    formik.values.recurrence,
    formik.values.parasyte,
    formik.values.dhp_tablet,
    formik.values.primaquin_tablet,
    formik.values.kina_tablet,
    formik.values.kina_injeksi,
    formik.values.artesunat_injeksi,
    formik.values.doksisiklin,
    formik.values.tetrasiklin,
    formik.values.klindamisin,
  ]);

  useEffect(() => {
    fetchAllCadresData();
    fetchMbsDates();
    fetchSurveyContacts();
  }, []);

  useEffect(() => {
    // address state
    if (!formik.values.district_id) {
      formik.setFieldValue("subdistrict_id", "");
      formik.setFieldValue("fasyankes_id", "");
      formik.setFieldValue("urbanvillage_id", "");
      setAddressState({
        ...addressState,
        subdistricts: [],
        fasyankes: [],
        urbanvillages: [],
      });
    }
    if (!formik.values.subdistrict_id) {
      formik.setFieldValue("fasyankes_id", "");
      formik.setFieldValue("urbanvillage_id", "");
      setAddressState({
        ...addressState,
        fasyankes: [],
        urbanvillages: [],
      });
    }
    if (!formik.values.fasyankes_id) {
      formik.setFieldValue("urbanvillage_id", "");
      setAddressState({
        ...addressState,
        urbanvillages: [],
      });
    }
  }, [
    formik.values.district_id,
    formik.values.subdistrict_id,
    formik.values.fasyankes_id,
  ]);

  useEffect(() => {
    // domicile state
    if (formik.values.domicile_province_id === "") {
      formik.setFieldValue("domicile_district_id", "");
      formik.setFieldValue("domicile_subdistrict_id", "");
      formik.setFieldValue("domicile_fasyankes_id", "");
      setDomicileState({
        ...domicileState,
        districts: [],
        subdistricts: [],
        fasyankes: [],
      });
    }

    if (formik.values.domicile_district_id === "") {
      formik.setFieldValue("domicile_subdistrict_id", "");
      formik.setFieldValue("domicile_fasyankes_id", "");
      setDomicileState({
        ...domicileState,
        subdistricts: [],
        fasyankes: [],
      });
    }
    if (formik.values.domicile_subdistrict_id === "") {
      formik.setFieldValue("domicile_fasyankes_id", "");
      setDomicileState({
        ...domicileState,
        fasyankes: [],
      });
    }
  }, [
    formik.values.domicile_province_id,
    formik.values.domicile_district_id,
    formik.values.domicile_subdistrict_id,
  ]);

  useEffect(() => {
    if (formik.values.lab_checking_type === "Mikroskop") {
      generateSlideCode();
    }
  }, [
    formik.values.reg_number,
    formik.values.lab_checking_date,
    formik.values.lab_checking_type,
  ]);

  useEffect(() => {
    fetchAddressProvincies();
    if (formik.values.province_id) {
      fetchAddressDistrictByProvince(formik.values.province_id);
    }
    if (formik.values.district_id) {
      fetchAddressSubdistrictsByDistrict(formik.values.district_id);
    }
    if (
      formik.values.district_id &&
      currentUser?.fasyankes_type_id !== "jnsfas-01" &&
      !formik.values.subdistrict_id
    ) {
      fetchAddressFasyankesByDistrict(formik.values.district_id);
    }
    if (formik.values.subdistrict_id) {
      fetchAddressUrbanvillagesBySubdistrict(formik.values.subdistrict_id);
      fetchAddressFasyankesBySubdistrict(formik.values.subdistrict_id);
    }
  }, [
    formik.values.province_id,
    formik.values.district_id,
    formik.values.subdistrict_id,
  ]);

  useEffect(() => {
    fetchDomicileProvincies();
    if (formik.values.domicile_province_id) {
      fetchDomicileDistrictsByProvince(formik.values.domicile_province_id);
    }
    if (formik.values.domicile_district_id) {
      fetchDomicileSubdistrictsByDistrict(formik.values.domicile_district_id);
    }
    if (formik.values.domicile_subdistrict_id) {
      fetchDomicileFasyankesBySubdistrict(
        formik.values.domicile_subdistrict_id
      );
    }
  }, [
    formik.values.domicile_province_id,
    formik.values.domicile_district_id,
    formik.values.domicile_subdistrict_id,
  ]);

  useEffect(() => {
    fetchReferenceProvincies();
    if (formik.values.ref_province_id) {
      fetchReferenceDistrictsByProvince(formik.values.ref_province_id);
    }
    if (formik.values.ref_district_id) {
      fetchReferenceFasyankesByDistrict(formik.values.ref_district_id);
    }
  }, [formik.values.ref_province_id, formik.values.ref_district_id]);

  useEffect(() => {
    if (formik.values.case_from === "Klinik Perdhaki") {
      fetchAllSrData();
    }
    if (formik.values.sr_id) {
      fetchAllSsrDataBySr(formik.values.sr_id);
    }
  }, [formik.values.case_from, formik.values.sr_id]);

  useEffect(() => {
    if (formik.values.survey_contact_date) {
      fetchSurveyContacts(formik.values.survey_contact_date);
    }
  }, [formik.values.survey_contact_date]);

  useEffect(() => {
    if (formik.values.discovery_activities === "MBS") {
      fetchMbsDates();
    }
  }, [formik.values.discovery_activities]);

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      const dataFromStorage = JSON.parse(
        getDataFromLocalStorage("SELECTED_ITEM")
      );

      setSelectedItem(dataFromStorage);

      let caseFrom = "";
      let otherCaseFrom = "";

      if (
        CaseFromOptions.filter((e) => e.value === dataFromStorage?.case_from)
          .length > 0
      ) {
        caseFrom = dataFromStorage?.case_from;
      } else {
        caseFrom = "Lainnya";
        otherCaseFrom = dataFromStorage?.case_from?.split("_")[1];
      }

      let selectedCadres = [];

      if (cadresData.length > 0) {
        const data = dataFromStorage?.cadres_id?.map((cadreId) => {
          const filteredCadre = cadresData?.find(
            (item) => item?.value === cadreId
          );
          return filteredCadre;
        });
        selectedCadres = data;
      }

      let selectedJob = "";
      let otherJob = "";

      if (
        JobsListOptions.filter((e) => e.value === dataFromStorage?.job).length >
        0
      ) {
        selectedJob = dataFromStorage?.job;
      } else {
        selectedJob = "Lainnya";
        otherJob = dataFromStorage?.job?.split("_")[1];
      }

      let selectedSymthomatic = "";
      let otherSympthomatic = "";

      if (dataFromStorage?.sympthomatic !== null) {
        if (
          SymthomaticOptions.filter(
            (e) => e.value === dataFromStorage?.sympthomatic
          ).length > 0
        ) {
          selectedSymthomatic = dataFromStorage?.sympthomatic;
        } else {
          selectedSymthomatic = "Lainnya";
          otherSympthomatic = dataFromStorage?.sympthomatic?.split("_")[1];
        }
      }

      let selectedReason = "";
      let otherReason = "";

      if (dataFromStorage?.not_standard_medication_reason !== null) {
        if (
          StandarMedicationReasonOptions.filter(
            (e) => e.value === dataFromStorage?.not_standard_medication_reason
          ).length > 0
        ) {
          selectedReason = dataFromStorage?.not_standard_medication_reason;
        } else {
          selectedReason = "Lainnya";
          otherReason =
            dataFromStorage?.not_standard_medication_reason?.split("_")[1];
        }
      }

      formik.setValues({
        is_foreign_nationality: dataFromStorage?.is_foreign_nationality,
        not_have_ktp: dataFromStorage?.not_have_ktp,
        ktp: dataFromStorage?.ktp,
        name: dataFromStorage?.name,
        age: dataFromStorage?.age,
        age_type: dataFromStorage?.age_type,
        gender: dataFromStorage?.gender,
        oap: dataFromStorage?.oap,
        pregnancy: dataFromStorage?.pregnancy,
        province_id: dataFromStorage?.province_id,
        district_id: dataFromStorage?.district_id,
        subdistrict_id: dataFromStorage?.subdistrict_id,
        fasyankes_id: dataFromStorage?.fasyankes_id,
        urbanvillage_id: dataFromStorage?.urbanvillage_id,
        sub_village: dataFromStorage?.sub_village,
        address: dataFromStorage?.address,
        is_domicile_outside: dataFromStorage?.is_domicile_outside,
        domicile_province_id: dataFromStorage?.domicile_province_id,
        domicile_district_id: dataFromStorage?.domicile_district_id,
        domicile_subdistrict_id: dataFromStorage?.domicile_subdistrict_id,
        domicile_fasyankes_id: dataFromStorage?.domicile_fasyankes_id,

        case_from: caseFrom,
        other_case_from: otherCaseFrom,
        discovery_activities: dataFromStorage?.discovery_activities,
        mbs_date: dataFromStorage?.mbs_date,
        survey_contact_date: dataFromStorage?.survey_contact_date,
        first_case_trigger_survey_contact:
          dataFromStorage?.first_case_trigger_survey_contact,
        sr_id: dataFromStorage?.sr_id,
        ssr_id: dataFromStorage?.ssr_id,
        house_visit: dataFromStorage?.house_visit,
        isCadre: dataFromStorage?.cadres_id?.length > 0 ? "Ya" : "Tidak",
        cadres_id: selectedCadres,
        lab_checking_type: dataFromStorage?.lab_checking_type,
        total_rdt: dataFromStorage?.total_rdt,
        reg_number:
          dataFromStorage?.slide_code !== null
            ? dataFromStorage?.slide_code?.split("/")[2]
            : null,
        slide_code: dataFromStorage?.slide_code,
        patient_visite_date: dataFromStorage?.patient_visite_date,
        lab_checking_date: dataFromStorage?.lab_checking_date,
        parasyte: dataFromStorage?.parasyte || [],

        job: selectedJob,
        other_job: otherJob,
        disease_degree: dataFromStorage?.disease_degree,
        body_temperature: dataFromStorage?.body_temperature,
        sympthomatic: selectedSymthomatic,
        other_sympthomatic: otherSympthomatic,
        sympthomatic_date: dataFromStorage?.sympthomatic_date,
        gametosit: dataFromStorage?.gametosit,
        parasyte_density: dataFromStorage?.parasyte_density,
        weight: dataFromStorage?.weight,
        recurrence: dataFromStorage?.recurrence,
        anemia_status: dataFromStorage?.anemia_status,
        medication_date: dataFromStorage?.medication_date,
        medication: dataFromStorage?.medication,
        dhp_tablet: dataFromStorage?.medication?.dhp_tablet,
        primaquin_tablet: dataFromStorage?.medication?.primaquin_tablet,
        kina_tablet: dataFromStorage?.medication?.kina_tablet,
        artesunat_injeksi: dataFromStorage?.medication?.artesunat_injeksi,
        kina_injeksi: dataFromStorage?.medication?.kina_injeksi,
        doksisiklin: dataFromStorage?.medication?.doksisiklin,
        tetrasiklin: dataFromStorage?.medication?.tetrasiklin,
        klindamisin: dataFromStorage?.medication?.klindamisin,
        standard_medication_status: dataFromStorage?.standard_medication_status,
        not_standard_medication_reason: selectedReason,
        other_not_standard_medication_reason: otherReason,
        treatment: dataFromStorage?.treatment,
        death_with_malaria: dataFromStorage?.death_with_malaria,
        referred_to_other_faskes: dataFromStorage?.referred_to_other_faskes,
        ref_reason: dataFromStorage?.ref_reason,
        ref_date: dataFromStorage?.ref_date,
        ref_province_id: dataFromStorage?.ref_province_id,
        ref_district_id: dataFromStorage?.ref_district_id,
        ref_fasyankes_id: dataFromStorage?.ref_fasyankes_id,
      });
    }
  }, [cadresData]);

  const renderStepFormComponent = () => {
    switch (formStep) {
      case 1:
        return (
          <PersonalDataStepForm
            formik={formik}
            addressData={addressState}
            domicileData={domicileState}
            onNextStep={handleNextStep}
            changeMode={changeMode}
          />
        );
      case 2:
        return (
          <DiscoveryStatusStepForm
            formik={formik}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
            cadresData={cadresData}
            srData={srData}
            ssrData={ssrData}
            mbsDates={mbsDates}
            surveyContactCases={surveyContactCases}
            getSurveyContacts={fetchSurveyContacts}
          />
        );
      case 3:
        return (
          <TreatmentStepForm
            formik={formik}
            onPrevStep={handlePrevStep}
            referenceData={referenceState}
            pageMode={pageMode}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* Breadcrumbs nav */}
      <BreadcrumbNav
        items={[
          {
            label: "Register Malaria 1",
            onClick: () => changeMode(PageMode.view),
          },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Register Malaria 1"
                : "Edit Data Register Malaria 1",
          },
        ]}
      />

      <h1 className="page-title">
        {pageMode === PageMode.add
          ? "Tambah Data Register Malaria 1"
          : "Edit Data Register Malaria 1"}
      </h1>

      <FormStepIndicatorComponent step={formStep} />

      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          NOTE<span>*(WAJIB DIISI)</span>
        </p>
        {renderStepFormComponent()}
      </div>
    </>
  );
};

export default FormComponent;
