import React, { Component } from "react";
import { PageMode, Source } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import Endemisitas from "services/Endemisitas";
import { connect } from "react-redux";
import { ArrowLeft } from "react-feather";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Wilayah from "services/Wilayah";
import { isEmpty } from "utils/common";

const EndemisitasService = new Endemisitas();
const WilayahService = new Wilayah();

class DataEndmisitas extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      selectedItem: null,
      provincies: [],
      year: "",
      province_id: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllData();
    this.getProvincies();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllData();
    });
  };

  getAllData = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      const { page, limit, year, province_id } = this.state;
      let searchBy = [];
      let searchValue = [];
      if (!isEmpty(year)) {
        searchBy.push("year");
        searchValue.push(year);
      }
      if (!isEmpty(province_id)) {
        searchBy.push("province_id");
        searchValue.push(province_id?.split("|")[0]);
      }

      await EndemisitasService.getAll({
        page,
        limit,
        searchBy: searchBy.join(),
        searchValue: searchValue.join(),
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (id) => {
    this.setState({ selectedItem: id }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            EndemisitasService.remove(id)
              .then((res) => {
                this.getAllData();
                toast.success("Data Dasar Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllData();
    });
  };

  onSearch = (e) => {
    const val = e.target.value;
    this.setState({ searchBy: "urbanvillage_id", searchValue: val }, () => {
      this.getAllData();
    });
  };

  onFilter = (e) => {
    this.getAllData();
  };

  getProvincies = async () => {
    await WilayahService.getAll(Source.PROVINCE, { page: 1, limit: 999 })
      .then((res) => {
        this.setState({
          provincies: res.province,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      mode,
      selectedItem,
      data,
      loading,
      page,
      provincies,
      year,
      province_id,
    } = this.state;

    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}
        <div className="title font-weight-bold">Data Endemisitas</div>
        <>
          {mode === PageMode.view && (
            <Table
              data={data}
              loading={loading}
              currentPage={page}
              onEdit={this.onEdit}
              onRemove={this.onRemove}
              onSearch={this.onSearch}
              changeMode={this.changeMode}
              onPageChange={this.onPageChange}
              onFilter={this.onFilter}
              year={year}
              handleInputChange={this.handleInputChange}
              provincies={provincies}
              province_id={province_id}
            />
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              data={selectedItem}
              provincies={provincies}
              onFilter={this.onFilter}
            />
          )}
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(DataEndmisitas);
