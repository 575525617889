import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none", padding: 0 }}>
          <th style={{ border: "none", paddingBottom: "0" }}>
            <h3>SISMAL v3, KINERJA KADER MMP</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tanggal
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.dateStart} - {reportInfo?.dateEnd}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>Tahun</th>
            <th>Bulan</th>
            <th>ID MMP</th>
            <th>Jumlah Orang Yang Menjadi Tanggung Jawab Kader</th>
            <th>Jumlah Orang Baru Yang Masuk</th>
            <th>Jumlah Orang Baru Yang Keluar</th>
            <th>Jumlah Pemeriksaan Pada Orang Yang Masuk</th>
            <th>Jumlah Pemeriksaan Pada Orang Yang Keluar</th>
          </tr>
        </thead>
        <tbody>
          {reportsData.length !== 0 ? (
            reportsData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.year}</td>
                <td>{monthConverter(item?.month)}</td>
                <td>{item?.assignment_place_mmp_id}</td>
                <td>{item?.total_person_responsibility}</td>
                <td>{item?.total_person_in}</td>
                <td>{item?.total_person_out}</td>
                <td>{item?.total_person_check_in}</td>
                <td>{item?.total_person_check_out}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={11}>Tidak Ada Data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
