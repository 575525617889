import React, { Component } from "react";
import Maps from "../../../../../components/Maps";
import { InputField, InputSelect } from "../../../../../components";
import InputSelect2 from "components/atoms/InputSelect";
import { PageMode } from "../../../../../utils/constant";
import InputDateTime from "components/InputDateTime";
import { toast } from "react-toastify";
import { getCurrentUserFromLocalStorage, isEmpty } from "utils/common";
export default class InvestigationResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recurrenceSelect: true,
      parasyteStatus: false,
      errors: {},
    };
  }

  componentDidMount() {
    const {
      mode,
      handleInputChange,
      data,
      handleChangeDistrict,
      handleChangeSubDistrict,
      handleChangeProvince,
      handleChangeFaskes,
      handleChangeCountry,
      handleChangeUrbanVillage,
      dataRegmal,
    } = this.props;

    this.checkParasyte(dataRegmal.parasyte);

    if (mode === PageMode.edit) {
      handleChangeProvince({
        value: data.province_id,
      });
      handleChangeDistrict({
        value: data.district_id,
      });
      handleChangeSubDistrict({
        value: data.subdistrict_id,
      });
      handleChangeFaskes({
        value: data.fasyankes_id,
      });
      handleChangeUrbanVillage({
        value: data.urbanvillage_id,
      });
      handleChangeCountry({
        value: data.country_origin,
      });
      handleInputChange({
        preventDefault: () => { },
        target: {
          name: "mmp_case",
          value: data.mmp_case,
        },
      });
    }
  }

  onSave = (e) => {
    e.preventDefault();
    const { values, dataRegmal } = this.props;
    let isValid = true;
    if (values.transmission.value === "Nyamuk") {
      if (this.state.parasyteStatus) {
        if (dataRegmal.recurrence === "Relaps") {
          if (
            !this.validationHandler(
              "initial_case_date",
              "Tanggal kasus awal wajib diisi"
            )
          )
            isValid = false;
        }
      }
      if (
        !this.validationHandler(
          "transmission_origin",
          "Asal penularan wajib diisi"
        )
      )
        isValid = false;
      if (
        (!isEmpty(values.transmission_origin.value) &&
          values.transmission_origin.value == "Luar Wilayah Kabupaten_Kota") ||
        values.transmission_origin.value == "Luar Wilayah Provinsi"
      ) {
        if (
          !this.validationHandler(
            "province_id",
            "Provinsi asal penularan wajib diisi"
          )
        )
          isValid = false;
        if (
          !this.validationHandler(
            "district_id",
            "Kabupaten/Kota asal penularan wajib diisi"
          )
        )
          isValid = false;
        if (
          !this.validationHandler(
            "address_of_infection",
            "Alamat lengkap asal penularan wajib diisi"
          )
        )
          isValid = false;
      } else if (
        (!isEmpty(values.transmission_origin.value) &&
          values.transmission_origin.value == "Luar Wilayah Puskesmas") ||
        values.transmission_origin.value == "Dalam Wilayah Puskesmas"
      ) {
        if (
          !this.validationHandler(
            "district_id",
            "Kab/Kota asal penularan wajib diisi"
          )
        )
          isValid = false;
        if (
          !this.validationHandler(
            "subdistrict_id",
            "Kecamatan asal penularan wajib diisi"
          )
        )
          isValid = false;
        if (
          !this.validationHandler(
            "fasyankes_id",
            "Puskesmas asal penularan wajib diisi"
          )
        )
          isValid = false;
        if (
          !this.validationHandler(
            "urbanvillage_id",
            "Desa asal penularan wajib diisi"
          )
        )
          isValid = false;
        if (
          !this.validationHandler(
            "sub_village",
            "Dusun asal penularan wajib diisi"
          )
        )
          isValid = false;
        if (
          !this.validationHandler(
            "address_of_infection",
            "Alamat lengkap asal penularan wajib diisi"
          )
        )
          isValid = false;
      }
    }
    if (
      !this.validationHandler(
        "phone_number",
        "Nomor Telepon Pasien wajib diisi"
      )
    )
      isValid = false;
    if (!this.validationHandler("transmission", "Penularan wajib diisi"))
      isValid = false;

    if (!isEmpty(values.transmission_origin.value) && values.transmission_origin.value != "Luar Wilayah Negara") {
      if (!this.validationHandler("mmp_case", "Kasus pada MMP wajib diisi"))
        isValid = false;

      if (values.mmp_case.value === "Ya") {
        if (!this.validationHandler("mmp_id", "MMP ID wajib diisi"))
          isValid = false;
      }
    }

    if (isValid) {
      this.props.submitFormData();
    }
  };

  validationHandler = (name, errorMsg) => {
    const { parentStateChanger, values } = this.props;
    let isValid = true;
    if (
      values[name].value === "" ||
      values[name].value === null ||
      values[name].value === undefined
    ) {
      parentStateChanger(name, {
        value: "",
        isValid: false,
        message: `${errorMsg}`,
      });
      isValid = false;
    }
    return isValid;
  };

  backToPrevious = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  countryMapper = (e) => {
    const options = [];
    e.filter((x) => x.name !== "Indonesia").map((x) => {
      options.push({ value: x.name, label: x.name });
    });
    return options;
  };

  mapper = (e) => {
    const { values } = this.props;
    const user = getCurrentUserFromLocalStorage();
    const options = [];
    if (values.transmission_origin.value == "Luar Wilayah Provinsi") {
      e.filter((x) => x.id !== user?.province_id?.split("|")[0]).map((x) => {
        options.push({ value: `${x.id}|${x.name}`, label: x.name });
      });
    } else if (
      values.transmission_origin.value == "Luar Wilayah Kabupaten_Kota"
    ) {
      e.filter((x) => x.id !== user?.district_id?.split("|")[0]).map((x) => {
        options.push({ value: `${x.id}|${x.name}`, label: x.name });
      });
    } else if (values.transmission_origin.value == "Luar Wilayah Puskesmas") {
      e.filter((x) => x.id !== user?.fasyankes_id?.split("|")[0]).map((x) => {
        options.push({ value: `${x.id}|${x.name}`, label: x.name });
      });
    } else {
      e.map((x) => {
        options.push({ value: `${x.id}|${x.name}`, label: x.name });
      });
    }
    return options;
  };

  mmpMapper = (e) => {
    const options = [];
    e.map((x) => {
      const pkm = x.fasyankes_id?.split("|")[1];
      const village = x.urbanvillage_id?.split("|")[1];
      options.push({
        value: x.group_mmp_id,
        label: `${x.group_mmp_id} - ${pkm}/${village}/${x.mmp_type}/${x.group_leader_name}`,
      });
    });
    return options;
  };

  checkParasyte = (value) => {
    if (!isEmpty(value)) {
      if (
        value.includes("P.Vivax") ||
        value.includes("P.Ovale") ||
        value.includes("P.Mix")
      ) {
        this.setState({ parasyteStatus: true });
      }
    }
  };

  render() {
    const {
      values,
      handleInputChange,
      handleChangeProvince,
      handleChangeDistrict,
      handleChangeSubDistrict,
      handleChangeUrbanVillage,
      handleChangeFaskes,
      handleChangeCountry,
      countries,
      provincies,
      districts,
      subdistricts,
      urbanvillages,
      fasyankes,
      mmps,
      setCoord,
      parentStateChanger,
      user,
      dataRegmal,
    } = this.props;
    const { parasyteStatus } = this.state;

    return (
      <div className="form-wrapper">
        <div className="form-title">
          <p className="font-weight-bold">Hasil Penyelidikan Epidemiologi</p>
        </div>

        <InputSelect
          label="Penularan"
          name="transmission"
          onChange={(e) => {
            if (e.target.value === "Nyamuk") {
              this.setState({ recurrenceSelect: false });
              parentStateChanger("case_classification", {
                value: "",
                isValid: true,
                message: "",
              });
            } else {
              this.setState({ recurrenceSelect: true });
              parentStateChanger("case_classification", {
                value: `Induced${dataRegmal.recurrence === "Relaps" ? " Relaps" : ""}`,
                isValid: true,
                message: "",
              });
            }
            parentStateChanger("transmission_origin", {
              value: "",
              isValid: true,
              message: "",
            });
            parentStateChanger("indigenous", {
              value: "",
              isValid: true,
              message: "",
            });
            parentStateChanger("recurrence", {
              value: "",
              isValid: true,
              message: "",
            });
            handleInputChange(e);
          }}
          value={values.transmission.value}
          placeholder="Pilih"
          data={[
            { value: "Nyamuk", label: "Nyamuk" },
            { value: "Transfusi Darah", label: "Transfusi Darah" },
            { value: "Kongenital", label: "Kongenital" },
          ]}
          isRequired={true}
          hasError={!values.transmission.isValid}
          errorMessage={values.transmission.message}
        />

        {parasyteStatus && (
          <>
            <InputField
              label="Kekambuhan"
              name="recurrence"
              value={dataRegmal && dataRegmal.recurrence}
              disabled={true}
              isHidden={values.transmission.value !== "Nyamuk"}
            />

            {dataRegmal.recurrence === "Relaps" && (
              <InputDateTime
                label="Tanggal Kasus Awal (Relaps)"
                name="initial_case_date"
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(e) =>
                  handleInputChange({
                    preventDefault: () => { },
                    target: {
                      name: "initial_case_date",
                      value: moment(e).format("YYYY-MM-DD"),
                    },
                  })
                }
                isValidDate={(current) => {
                  const date = moment().subtract(3, "years");
                  return current >= date;
                }}
                isRequired={true}
                closeOnClickOutside={true}
                value={values.initial_case_date.value}
                isInline={true}
                hasError={!values.initial_case_date.isValid}
                errorMessage={values.initial_case_date.message}
                isHidden={values.transmission.value !== "Nyamuk"}
              />
            )}
          </>
        )}

        {values.transmission.value === "Nyamuk" && (
          <>
            <InputSelect
              label=" Asal Penularan"
              name="transmission_origin"
              onChange={handleInputChange}
              value={values.transmission_origin.value}
              placeholder="Pilih"
              data={[
                {
                  value: "Dalam Wilayah Puskesmas",
                  label: "Dalam Wilayah Puskesmas",
                },
                {
                  value: "Luar Wilayah Puskesmas",
                  label: "Luar Wilayah Puskesmas",
                },
                {
                  value: "Luar Wilayah Kabupaten_Kota",
                  label: "Luar Wilayah Kabupaten/Kota",
                },
                {
                  value: "Luar Wilayah Provinsi",
                  label: "Luar Wilayah Provinsi",
                },
                {
                  value: "Luar Wilayah Negara",
                  label: "Luar Wilayah Negara",
                },
              ]}
              isRequired={true}
              hasError={!values.transmission_origin.isValid}
              errorMessage={values.transmission_origin.message}
            />

            {values.dateArrivalOpen && dataRegmal.recurrence === "Relaps" && (
              <InputDateTime
                label="Tanggal kedatangan kasus dari luar wilayah"
                name="date_arrival"
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(e) =>
                  handleInputChange({
                    preventDefault: () => { },
                    target: {
                      name: "date_arrival",
                      value: moment(e).format("YYYY-MM-DD"),
                    },
                  })
                }
                closeOnClickOutside={true}
                value={values.date_arrival.value}
                isInline={true}
                hasError={!values.date_arrival.isValid}
                errorMessage={values.date_arrival.message}
              />
            )}
            {values.transmission_origin.value !== "Luar Wilayah Negara" && (
              <>
                <InputSelect2
                  label={"Nama Provinsi asal penularan"}
                  name={"province_id"}
                  placeholder={"Pilih Provinsi"}
                  data={this.mapper(provincies)}
                  onChange={handleChangeProvince}
                  value={
                    this.mapper(provincies).find(
                      (item) => item.value === values.province_id?.value
                    ) || null
                  }
                  error={!values.province_id.isValid}
                  errorMessage={values.province_id.message}
                  isRequired={
                    values.transmission_origin.value === "Luar Wilayah Negara"
                      ? false
                      : true
                  }
                  isDisabled={!values.enable_province_field}
                  disableOptById={user.province_id}
                />

                <InputSelect2
                  label={"Nama Kab/Kota asal penularan"}
                  name={"district_id"}
                  placeholder={"Pilih Kabupaten/Kota"}
                  data={this.mapper(districts)}
                  onChange={handleChangeDistrict}
                  value={
                    this.mapper(districts).find(
                      (item) => item.value === values.district_id?.value
                    ) || null
                  }
                  error={!values.district_id.isValid}
                  errorMessage={values.district_id.message}
                  isRequired={
                    values.transmission_origin.value === "Luar Wilayah Negara"
                      ? false
                      : true
                  }
                  isDisabled={!values.enable_district_field}
                  disableOptById={user.district_id}
                />

                <InputSelect2
                  label={"Nama Kecamatan asal penularan"}
                  name={"subdistrict_id"}
                  placeholder={"Pilih Kecamatan"}
                  data={this.mapper(subdistricts)}
                  onChange={handleChangeSubDistrict}
                  value={
                    this.mapper(subdistricts).find(
                      (item) => item.value === values.subdistrict_id?.value
                    ) || null
                  }
                  error={!values.subdistrict_id.isValid}
                  errorMessage={values.subdistrict_id.message}
                  isDisabled={!values.enable_subdistrict_field}
                  isRequired={
                    values.transmission_origin.value ===
                      "Luar Wilayah Provinsi" ||
                      values.transmission_origin.value ===
                      "Luar Wilayah Kabupaten_Kota" ||
                      values.transmission_origin.value === "Luar Wilayah Negara"
                      ? false
                      : true
                  }
                  disableOptById={user.subdistrict_id}
                />
                <InputSelect2
                  label={"Nama Puskesmas asal penularan"}
                  name={"fasyankes_id"}
                  placeholder={"Pilih Puskesmas"}
                  data={this.mapper(fasyankes)}
                  onChange={handleChangeFaskes}
                  value={
                    this.mapper(fasyankes).find(
                      (item) => item.value == values.fasyankes_id?.value
                    ) || null
                  }
                  error={!values.fasyankes_id.isValid}
                  errorMessage={values.fasyankes_id.message}
                  isDisabled={!values.enable_fasyankes_field}
                  isRequired={
                    values.transmission_origin.value ===
                      "Luar Wilayah Provinsi" ||
                      values.transmission_origin.value ===
                      "Luar Wilayah Kabupaten_Kota" ||
                      values.transmission_origin.value === "Luar Wilayah Negara"
                      ? false
                      : true
                  }
                />

                <InputSelect2
                  label={"Nama Desa asal penularan"}
                  name={"urbanvillage_id"}
                  placeholder={"Pilih Desa"}
                  data={this.mapper(urbanvillages)}
                  onChange={handleChangeUrbanVillage}
                  value={
                    this.mapper(urbanvillages).find(
                      (item) => item.value === values.urbanvillage_id?.value
                    ) || null
                  }
                  error={!values.urbanvillage_id.isValid}
                  errorMessage={values.urbanvillage_id.message}
                  isDisabled={!values.enable_urbanvillage_field}
                  isRequired={
                    values.transmission_origin.value ===
                      "Luar Wilayah Provinsi" ||
                      values.transmission_origin.value ===
                      "Luar Wilayah Kabupaten_Kota" ||
                      values.transmission_origin.value === "Luar Wilayah Negara"
                      ? false
                      : true
                  }
                  disableOptById={
                    values.transmission_origin.value ===
                      "Dalam Wilayah Puskesmas"
                      ? false
                      : user.urbanvillage_id
                  }
                />

                <InputField
                  label="Nama Dusun asal penularan"
                  name="sub_village"
                  type="textarea"
                  placeholder="Nama Dusun"
                  onChange={handleInputChange}
                  value={values.sub_village.value}
                  isDisabled={!values.enable_sub_village_name_field}
                  isRequired={
                    values.transmission_origin.value ===
                      "Luar Wilayah Provinsi" ||
                      values.transmission_origin.value ===
                      "Luar Wilayah Kabupaten_Kota" ||
                      values.transmission_origin.value === "Luar Wilayah Negara"
                      ? false
                      : true
                  }
                  hasError={!values.sub_village.isValid}
                  errorMessage={values.sub_village.message}
                />

                <InputField
                  label="Alamat lengkap asal penularan"
                  name="address_of_infection"
                  type="textarea"
                  placeholder="Alamat lengkap"
                  onChange={handleInputChange}
                  value={values.address_of_infection.value}
                  isDisabled={!values.enable_address_of_infection_field}
                  isRequired={
                    values.transmission_origin.value === "Luar Wilayah Negara"
                      ? false
                      : true
                  }
                  hasError={!values.address_of_infection.isValid}
                  errorMessage={values.address_of_infection.message}
                />
              </>
            )}

            <div className="form-group">
              <label htmlFor="location">Pilih Lokasi</label>
              <Maps
                onChangeLocation={(address, coordinate) => {
                  setCoord(coordinate);
                }}
              />
              <div className="alert-warning p-2 my-2">
                <p>
                  <b>Perhatian : </b>
                </p>
                <ul>
                  <li>
                    Untuk Kasus Indigenous, titik Koordinat adalah tempat
                    penularan
                  </li>
                  <li>
                    Untuk Kasus Import, titik Koodinat adalah rumah/domisili
                    pasien
                  </li>
                  <li>
                    Untuk hasil optimal Lokasi Maps, pastikan terhubung dengan
                    internet
                  </li>
                </ul>
              </div>

              <input
                type="text"
                className="form-control"
                name="coordinate"
                placeholder="Tititk Koordinat akan digenerate otomatis"
                onChange={handleInputChange}
                defaultValue={values.coordinate.value}
                readOnly
              />
            </div>
          </>
        )}

        {values.fasyankes_id.value && (
          <InputSelect
            label="Kasus pada MMP"
            name="mmp_case"
            onChange={handleInputChange}
            value={values.mmp_case.value}
            placeholder="Pilih"
            data={[
              { value: "Ya", label: "Ya" },
              { value: "Tidak", label: "Tidak" },
            ]}
            isRequired={true}
            hasError={!values.mmp_case.isValid}
            errorMessage={values.mmp_case.message}
          />
        )}

        {values.mmp_case.value === "Ya" && (
          <InputSelect
            label="ID MMP"
            name="mmp_id"
            onChange={handleInputChange}
            value={values.mmp_id.value}
            placeholder="Pilih"
            data={this.mmpMapper(mmps)}
            hasError={!values.mmp_id.isValid}
            errorMessage={values.mmp_id.message}
            isRequired={true}
          />
        )}

        {values.transmission_origin.value === "Luar Wilayah Negara" && (
          <>
            <InputSelect2
              label={"Nama Negara Asal Penularan"}
              name={"country_origin"}
              placeholder={"Pilih Negara"}
              data={this.countryMapper(countries)}
              onChange={handleChangeCountry}
              value={this.countryMapper(countries).find(
                (item) => item.value === values.country_origin.value
              )}
              error={!values.country_origin.isValid}
              errorMessage={values.country_origin.message}
              isDisabled={!values.enable_country_field}
              isRequired={
                values.transmission_origin.value === "Luar Wilayah Negara"
                  ? true
                  : false
              }
            />

            <InputField
              label="Alamat lengkap negara asal"
              name="address"
              type="textarea"
              placeholder=""
              isRequired={
                values.transmission_origin.value === "Luar Wilayah Negara"
                  ? true
                  : false
              }
              onChange={handleInputChange}
              value={values.address.value}
              isDisabled={!values.enable_country_field}
            />
          </>
        )}

        <InputField
          label="Nomor Telepon Pasien"
          name="phone_number"
          type="numberOnly"
          maxLength={15}
          placeholder="08123456789"
          onChange={handleInputChange}
          value={values.phone_number.value}
          isRequired={true}
          hasError={!values.phone_number.isValid}
          errorMessage={values.phone_number.message}
        />

        <InputField
          label="Klasifikasi Kasus"
          name="case_classification"
          onChange={handleInputChange}
          value={values.case_classification.value}
          isDisabled={true}
        />

        {values.case_classification.value === "Indigenous" && (
          <InputSelect
            label="Jika indigenous, apakah kasus adalah introduce"
            name="indigenous"
            onChange={handleInputChange}
            value={values.indigenous.value}
            placeholder="Pilih"
            data={[
              { value: "Ya", label: "Ya" },
              { value: "Tidak", label: "Tidak" },
            ]}
          />
        )}

        <div className="form-action d-flex justify-content-end">
          <button
            className="btn btn-teal-outline font-weight-bold mr-2"
            onClick={this.backToPrevious}
          >
            Kembali
          </button>
          <button
            className="btn btn-teal font-weight-bold"
            onClick={this.onSave}
            disabled={values.isLoading}
          >
            {values.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
          </button>
        </div>
      </div>
    );
  }
}
