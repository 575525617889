import jwtDecode from "jwt-decode";
import AuthService from "../services/Auth";
import {
  CURRENT_USER_KEY,
  ErrorMessage,
  MonthOptions,
  TOKEN_KEY,
  requestStatus,
} from "./constant";
import { toast } from "react-toastify";

export const monthList = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const monthListObject = [
  {
    label: "Januari",
    value: "01",
  },
  {
    label: "Februari",
    value: "02",
  },
  {
    label: "Maret",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "Mei",
    value: "05",
  },
  {
    label: "Juni",
    value: "06",
  },
  {
    label: "Juli",
    value: "07",
  },
  {
    label: "Agustus",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "Oktober",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "Desember",
    value: "12",
  },
];

const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getDataFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    if (data === null || data === undefined || data === "undefined") {
      return null;
    }
    return JSON.parse(data);
  } catch (err) {
    console.log(err);
  }
  return null;
};

const removeDataFromLocalStorage = (key) => {
  if (Array.isArray(key)) {
    key.forEach((value) => {
      localStorage.removeItem(value);
    });
  } else {
    localStorage.removeItem(key);
  }
};

const isAuthenticated = () => {
  const currentUser = getDataFromLocalStorage(CURRENT_USER_KEY);
  return currentUser && currentUser.isAuthenticated;
};

const setTokenToLocalStorage = (token) => {
  setDataToLocalStorage(TOKEN_KEY, token);
};

const getTokenFromLocalStorage = () => {
  return getDataFromLocalStorage(TOKEN_KEY);
};

const setCurrentUserToLocalStorage = (data) => {
  setDataToLocalStorage(CURRENT_USER_KEY, data);
};

const getCurrentUserFromLocalStorage = () => {
  return getDataFromLocalStorage(CURRENT_USER_KEY);
};

const removeCurrentUserFromLocalStorage = () => {
  removeDataFromLocalStorage([CURRENT_USER_KEY, TOKEN_KEY]);
};

const decodeToken = (token) => {
  try {
    const tokenSplitted = token ? token?.split(" ") : null;
    if (tokenSplitted && tokenSplitted.length === 2) {
      const decoded = jwtDecode(tokenSplitted[1]);
      return decoded;
    }
  } catch (err) {
    return null;
  }
  return null;
};

const clearLocalStorage = () => localStorage.clear();

const errHandler = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        console.log(err);
        break;
      case 400:
        console.log(err);
        break;
      case 300:
        console.log(err);
        break;
      case 200:
        console.log(err);
        break;
      case 401:
        new AuthService().loggedOut();
        break;
      case 403:
        console.log(err);
        break;
      default:
        console.log(err);
        break;
    }
  }
};

const errValidation = (err) => {
  if (err && err.data && err.data.hasOwnProperty("validationError")) {
    return err.data.validationError;
  }
  return null;
};

const monthConverter = (month) => {
  return monthList[parseInt(month) - 1];
};

const isEmpty = (obj) => {
  if (
    obj === null ||
    obj === undefined ||
    typeof obj === "undefined" ||
    obj === "" ||
    obj === "undefined"
  ) {
    return true;
  } else if (Array.isArray(obj) && obj.length === 0) {
    return true;
  } else {
    return false;
  }
};

const nullConverter = (value) => {
  if (value === "") {
    return null;
  } else {
    return value;
  }
};

const errorToastNotif = (err) => {
  const message = err.response.data.message;
  switch (message) {
    case requestStatus.ServerError:
      toast.error("Gagal menyimpan data, terjadi kesalahan di server", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    case requestStatus.Forbidden:
      toast.error(ErrorMessage.UserNotVerified, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    case requestStatus.BadRequest:
      toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    case requestStatus.Conflict:
      toast.error(ErrorMessage.DataAlreadyExist, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    default:
      toast.error(`Gagal menyimpan data : ${message}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
  }
};

const successToastNotif = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: "colored",
    draggable: true,
  });
};

const monthValueConverter = (month) => {
  return monthList[month - 1];
};

const formatWeeklyDates = (dates) => {
  if (!dates) {
    return "";
  }

  const [year, month] = dates?.split("-");
  const weekNumber = parseInt(month, 10);
  return `M${weekNumber}`;
};

const splitter = (e, delimiter, idx) => {
  if (isEmpty(e)) return "";
  return e?.split(delimiter)[idx];
};

const generateMonthOptions = (startMonth) => {
  const startMonthIndex = MonthOptions.findIndex(
    (month) => month.value === startMonth
  );

  return MonthOptions.slice(startMonthIndex);
};

const separateByYear = (data) => {
  return data.reduce((acc, entry) => {
    const year = entry.dates.split("-")[0];
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(entry);
    return acc;
  }, {});
};

const ensureFullYear = (data, year) => {
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const existingMonths = new Set(
    data.map((entry) => entry.dates.split("-")[1])
  );

  months.forEach((month) => {
    if (!existingMonths.has(month)) {
      data.push({
        id: "Indonesia",
        area: "Indonesia",
        dates: `${year}-${month}`,
        death: "0",
        value: "0",
      });
    }
  });

  // Sort the data by dates
  data.sort((a, b) => new Date(a.dates) - new Date(b.dates));

  return data;
};

const separateByYearAndEnsureFullYear = (data) => {
  const years = [...new Set(data.map((entry) => entry.dates.split("-")[0]))];

  years.forEach((year) => {
    ensureFullYear(data, year);
  });

  return data;
};

function numericFormatter(number) {
  if (number === null) {
    return "";
  }

  let numStr = number?.toString();

  let parts = numStr?.split(".");
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? "." + parts[1] : "";

  let regex = /\B(?=(\d{3})+(?!\d))/g;
  integerPart = integerPart.replace(regex, ",");

  return integerPart + decimalPart;
}

export {
  setDataToLocalStorage,
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  isAuthenticated,
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
  setCurrentUserToLocalStorage,
  getCurrentUserFromLocalStorage,
  removeCurrentUserFromLocalStorage,
  decodeToken,
  clearLocalStorage,
  errHandler,
  errValidation,
  monthConverter,
  isEmpty,
  nullConverter,
  errorToastNotif,
  monthValueConverter,
  successToastNotif,
  splitter,
  generateMonthOptions,
  separateByYear,
  ensureFullYear,
  separateByYearAndEnsureFullYear,
  formatWeeklyDates,
  numericFormatter,
};
