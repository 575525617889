import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import InputField from "components/atoms/InputField";
import TableDataComponent from "components/organisms/TableData";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KasusImpor from "services/KasusImpor";
import { useHistory } from "react-router-dom";
import { PageMode } from "utils/constant";
import {
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from "utils/common";

const KasusImporService = new KasusImpor();

const TableComponent = ({ changeMode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const history = useHistory();

  const fetchKasusImporData = async () => {
    setIsLoading(true);
    try {
      const response = await KasusImporService.getAll({
        district_id: currentUser?.district_id?.split("|")[0],
        page: dataState.page,
        limit: dataState.limit,
        searchBy: "case_classification",
        searchValue: "Import|Import Relaps",
        search: searchKeyword ?? "",
      });

      setDataState((prevState) => ({
        ...prevState,
        data: response.pe_case_import_case,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "SISMAL | Notifikasi Kasus Impor";
    removeDataFromLocalStorage("SELECTED_ITEM");
  }, []);

  useEffect(() => {
    fetchKasusImporData();
  }, [dataState.page, searchKeyword]);

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onDetailData = (data) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(data));
    changeMode(PageMode.detail);
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onDetailData(row.original)}
            btnType={"detail"}
          />
        </div>
      ),
    },
    {
      header: "Nama",
      accessorKey: "name",
    },
    {
      header: "Usia",
      accessorKey: "age",
      cell: ({ row }) => `${row.original.age} ${row.original.age_type}`,
    },
    {
      header: "Pekerjaan",
      accessorKey: "job",
    },
    // Pencatat
    {
      header: "Faskes Pencatat",
      accessorKey: "originator_fasyankes_name",
    },
    {
      header: "Kab Pencatat",
      accessorKey: "originator_district_name",
    },
    {
      header: "Provinsi Pencatat",
      accessorKey: "originator_province_name",
    },
    {
      header: "Faskes Domisili",
      accessorKey: "faskes_domisili",
    },
    // Pelaku
    {
      header: "Faskes Yang Melakukan PE",
      accessorKey: "user_fasyankes",
    },
    {
      header: "Kab Yang Melakukan PE",
      accessorKey: "user_district",
    },
    {
      header: "Provinsi Yang Melakukan PE",
      accessorKey: "user_province",
    },
    {
      header: "Kegiatan Penemuan Kasus",
      accessorKey: "discovery_activities",
    },
    {
      header: "Jenis Parasit",
      accessorKey: "parasyte",
      cell: ({ row }) =>
        row.original.parasyte ? row.original.parasyte?.join(", ") : "-",
    },
    {
      header: "Kekambuhan",
      accessorKey: "recurrence",
    },
    {
      header: "Tanggal Kasus Awal",
      accessorKey: "initial_case_date",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="page-title">Notifikasi Kasus Impor</h1>
        <div className="search" style={{ width: "25rem" }}>
          <InputField
            placeholder={"Cari nama/faskes disini..."}
            onChange={(e) =>
              setSearchKeyword(
                e.target.value && e.target.value.length >= 3
                  ? e.target.value
                  : ""
              )
            }
          />
        </div>
      </div>

      {/* table component here */}
      <TableDataComponent
        columnLength={columns.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState?.currentPage}
        limit={dataState?.limit}
        nextPage={dataState?.nextPage}
        previousPage={dataState?.previousPage}
        total={dataState?.totalData}
      />
    </>
  );
};

export default TableComponent;
