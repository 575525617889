import { Button, InputField, InputSelect } from "components";
import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus } from "utils/constant";
import EndemisCadrePerformance from "services/EndemisCadrePerformance";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";

const EndemisCadrePerformanceService = new EndemisCadrePerformance();

const lessActiveReason = [
  { value: "Meninggal", label: "Meninggal" },
  { value: "Pindah tempat", label: "Pindah tempat" },
  { value: "Sakit", label: "Sakit" },
  { value: "Bekerja ditempat lain", label: "Bekerja ditempat lain" },
  { value: "Berhenti", label: "Berhenti" },
  { value: "Kurangnya logistik", label: "Kurangnya logistik" },
  { value: "Lainnya", label: "Lainnya" },
];

export default class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      cadre_id: { value: this.props.cadreId, isValid: true, message: "" },
      month: { value: "", isValid: true, message: "" },
      year: { value: "", isValid: true, message: "" },
      target_cadre_inspection: { value: "", isValid: true, message: "" },
      total_house_responsibility: { value: "", isValid: true, message: "" },
      logistic_receive: { value: "", isValid: true, message: "" },
      total_house_visited_one_month: { value: "", isValid: true, message: "" },
      total_house_visited_two_month: { value: "", isValid: true, message: "" },
      total_house_visited: { value: 0, isValid: true, message: "" },
      total_cadre_inspection: { value: "", isValid: true, message: "" },
      total_suspect: { value: "", isValid: true, message: "" },
      total_survei_target: { value: "", isValid: true, message: "" },
      cadre_active: { value: "", isValid: true, message: "" },
      cadre_less_active_reason: { value: "", isValid: true, message: "" },
      other_less_active_reason: { value: "", isValid: true, message: "" },
      cadre_active_by_cadre_inspection: {
        value: "",
        isValid: true,
        message: "",
      },
      cadre_less_active_reason_by_cadre_inspection: {
        value: "",
        isValid: true,
        message: "",
      },
      other_cadre_less_active_reason_by_cadre_inspection: {
        value: "",
        isValid: true,
        message: "",
      },

      showLessActiveReasonField: false,
      showLessActiveReasonByCadreInspectionField: false,

      isLoading: false,
    };

    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    let obj = Object.assign({}, this.defaultValue);
    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;

      obj.cadre_id.value = data.cadre_id;
      obj.month.value = data.month;
      obj.year.value = data.year;
      obj.target_cadre_inspection.value = data.target_cadre_inspection;
      obj.total_house_responsibility.value = data.total_house_responsibility;
      obj.logistic_receive.value = data.logistic_receive;
      obj.total_house_visited_one_month.value =
        data.total_house_visited_one_month;
      obj.total_house_visited_two_month.value =
        data.total_house_visited_two_month;
      obj.total_house_visited.value = data.total_house_visited;
      obj.total_cadre_inspection.value = data.total_cadre_inspection;
      obj.total_suspect.value = data.total_suspect;
      obj.total_survei_target.value = data.total_survei_target;
      obj.cadre_active.value = data.cadre_active;
      if (
        lessActiveReason.filter(
          (e) => e.value === data.cadre_less_active_reason
        ).length > 0
      ) {
        obj.cadre_less_active_reason.value =
          data.cadre_less_active_reason === null
            ? ""
            : data.cadre_less_active_reason;
      } else {
        obj.cadre_less_active_reason.value = "Lainnya";
        obj.other_less_active_reason.value =
          data.cadre_less_active_reason &&
          data.cadre_less_active_reason?.split("_")[1];
      }

      if (
        lessActiveReason.filter(
          (e) => e.value === data.cadre_less_active_reason_by_cadre_inspection
        ).length > 0
      ) {
        obj.cadre_less_active_reason_by_cadre_inspection.value =
          data.cadre_less_active_reason_by_cadre_inspection === null
            ? ""
            : data.cadre_less_active_reason_by_cadre_inspection;
      } else {
        obj.cadre_less_active_reason_by_cadre_inspection.value = "Lainnya";
        obj.other_cadre_less_active_reason_by_cadre_inspection.value =
          data.cadre_less_active_reason_by_cadre_inspection &&
          data.cadre_less_active_reason_by_cadre_inspection?.split("_")[1];
      }

      obj.cadre_active_by_cadre_inspection.value =
        data.cadre_active_by_cadre_inspection;

      if (data.cadre_active === "Tidak Aktif") {
        obj.showLessActiveReasonField = true;
      }

      if (data.cadre_active === "Kurang Aktif") {
        obj.showLessActiveReasonField = true;
      }

      if (data.cadre_active === "Aktif") {
        obj.showLessActiveReasonField = false;
      }

      if (data.cadre_active_by_cadre_inspection === "Tidak Aktif") {
        obj.showLessActiveReasonByCadreInspectionField = true;
      }

      if (data.cadre_active_by_cadre_inspection === "Kurang Aktif") {
        obj.showLessActiveReasonByCadreInspectionField = true;
      }
    }
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    switch (name) {
      case "year":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
          },
          () => {
            this.onGetCalculate();
          }
        );
        break;
      case "month":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
          },
          () => {
            this.onGetCalculate();
          }
        );
        break;
      case "total_house_responsibility":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
          },
          () => {
            this.cadreActiveStatusGenerator();
            this.cadreAtiveStatusByInspectionGenerator();
          }
        );
        break;
      case "target_cadre_inspection":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
          },
          () => {
            this.cadreActiveStatusGenerator();
            this.cadreAtiveStatusByInspectionGenerator();
          }
        );
        break;
      case "total_house_visited_one_month":
        this.setState(
          { [name]: { value: value, isValid: true, message: "" } },
          () => {
            this.summaryHouseVisited();
            this.cadreActiveStatusGenerator();
          }
        );
        break;
      case "total_house_visited_two_month":
        this.setState(
          { [name]: { value: value, isValid: true, message: "" } },
          () => {
            this.summaryHouseVisited();
            this.cadreActiveStatusGenerator();
          }
        );
        break;
      case "total_cadre_inspection":
        this.setState(
          {
            [name]: { value: value, isValid: true, messsage: "" },
          },
          () => {
            this.cadreAtiveStatusByInspectionGenerator();
          }
        );
        break;
      case "target_cadre_inspection":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
          },
          () => {
            this.cadreAtiveStatusByInspectionGenerator();
          }
        );
        break;
      default:
        break;
    }

    this.setState({
      [name]: { value: value, isValid: true, message: "" },
    });
  };

  onGetCalculate = async () => {
    const { year, month, cadre_id } = this.state;

    await EndemisCadrePerformanceService.getCalculate({
      year: year.value,
      month: month.value,
      cadre_id: cadre_id.value,
    })
      .then((res) => {
        this.setState({
          total_suspect: {
            value: res.total_suspect,
            isValid: true,
            message: "",
          },
          total_cadre_inspection: {
            value: res.total_cadre_check,
            isValid: true,
            message: "",
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  nullConverter = (value) => {
    if (value === "") {
      return null;
    }
    return value;
  };

  onSave = async (e) => {
    e.preventDefault();

    const isValid = this.validationHandler();

    if (isValid) {
      this.setState({
        isLoading: true,
      });

      let payload = {
        cadre_id: this.nullConverter(this.state.cadre_id.value),
        month: this.nullConverter(this.state.month.value),
        year: this.nullConverter(this.state.year.value),
        target_cadre_inspection: this.nullConverter(
          this.state.target_cadre_inspection.value
        ),
        total_house_responsibility: this.nullConverter(
          this.state.total_house_responsibility.value
        ),
        logistic_receive: this.nullConverter(this.state.logistic_receive.value),
        total_house_visited_one_month: this.nullConverter(
          this.state.total_house_visited_one_month.value
        ),
        total_house_visited_two_month: this.nullConverter(
          this.state.total_house_visited_two_month.value
        ),
        total_house_visited: this.nullConverter(
          this.state.total_house_visited.value
        ),
        total_cadre_inspection: this.nullConverter(
          this.state.total_cadre_inspection.value
        ),
        total_suspect: this.nullConverter(this.state.total_suspect.value),
        total_survei_target: this.nullConverter(
          this.state.total_survei_target.value
        ),
        cadre_active: this.nullConverter(this.state.cadre_active.value),
        cadre_less_active_reason:
          this.state.cadre_less_active_reason.value === "Lainnya"
            ? `Lainnya_${this.state.other_less_active_reason.value}`
            : this.nullConverter(this.state.cadre_less_active_reason.value),
        cadre_active_by_cadre_inspection: this.nullConverter(
          this.state.cadre_active_by_cadre_inspection.value
        ),
        cadre_less_active_reason_by_cadre_inspection:
          this.state.cadre_less_active_reason_by_cadre_inspection.value ===
          "Lainnya"
            ? `Lainnya_${this.state.other_cadre_less_active_reason_by_cadre_inspection.value}`
            : this.nullConverter(
              this.state.cadre_less_active_reason_by_cadre_inspection.value
            ),
      };

      switch (this.props.mode) {
        case PageMode.add:
          await EndemisCadrePerformanceService.create(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Kinerja Berhasil Ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });

              console.log(err);

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
        case PageMode.edit:
          await EndemisCadrePerformanceService.update(
            payload,
            this.props.data.id
          )
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Kinerja Berhasil Diperbarui", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
      }
    }
  };

  summaryHouseVisited = () => {
    const oneMonth = this.state.total_house_visited_one_month.value;
    const twoMonth = this.state.total_house_visited_two_month.value;
    const totalVisited =
      (parseInt(oneMonth) || 0) + (parseInt(twoMonth) || 0) * 2;

    this.setState({
      total_house_visited: {
        value: totalVisited,
        isValid: true,
        message: "",
      },
    });
  };

  cadreActiveStatusGenerator = () => {
    let activeStatus = null;
    const houseResponsibility = parseInt(
      this.state.total_house_responsibility.value
    );

    const oneMonth = this.state.total_house_visited_one_month.value;
    const twoMonth = this.state.total_house_visited_two_month.value;
    const totalHouseVisited = (parseInt(oneMonth) || 0) + (parseInt(twoMonth) || 0) * 2;
    const result =(parseInt(totalHouseVisited) / parseInt(houseResponsibility)) * 100;

    if (result == 0) {
      activeStatus = "Tidak Aktif";
    } else if (result > 0 && result < 50) {
      activeStatus = "Kurang Aktif";
    } else if (result >= 50) {
      activeStatus = "Aktif";
    } else {
      activeStatus = "Tidak Aktif";
    }
    this.setState(
      {
        cadre_active: {
          value: activeStatus,
          isValid: true,
          message: "",
        },
      },
      () => {
        if (activeStatus == "Tidak Aktif" || activeStatus == "Kurang Aktif") {
          this.setState({
            showLessActiveReasonField: true,
          });
        } else {
          this.setState({
            showLessActiveReasonField: false,
          });
        }
      }
    );
  };

  cadreAtiveStatusByInspectionGenerator = () => {
    let activeStatus = null;
    const totalPemeriksaan = this.state.total_cadre_inspection.value;
    const targetPemeriksaan = this.state.target_cadre_inspection.value;

    const result =
      (parseInt(totalPemeriksaan) / parseInt(targetPemeriksaan)) * 100;

    if (result == 0) {
      activeStatus = "Tidak Aktif";
    } else if (result > 0 && result < 50) {
      activeStatus = "Kurang Aktif";
    } else if (result >= 50) {
      activeStatus = "Aktif";
    } else {
      activeStatus = null;
    }

    this.setState(
      {
        cadre_active_by_cadre_inspection: {
          value: activeStatus,
          isValid: true,
          message: "",
        },
      },
      () => {
        if (activeStatus === "Tidak Aktif" || activeStatus === "Kurang Aktif") {
          this.setState({
            showLessActiveReasonByCadreInspectionField: true,
          });
        } else {
          this.setState({
            showLessActiveReasonByCadreInspectionField: false,
          });
        }
      }
    );
  };

  validationHandler = () => {
    const {
      month,
      year,
      total_house_responsibility,
      logistic_receive,
      total_house_visited_one_month,
      total_house_visited_two_month,
      total_house_visited,
      total_cadre_inspection,
      total_suspect,
      total_survei_target,
      cadre_less_active_reason,
    } = this.state;

    let isValid = true;

    if (year.value === "") {
      year.isValid = false;
      year.message = "Tahun wajiib diisi";
      isValid = false;
    }

    if (month.value === "") {
      month.isValid = false;
      month.message = "Bulan wajib diisi";
      isValid = false;
    }

    if (logistic_receive.value === "") {
      logistic_receive.isValid = false;
      logistic_receive.message = "Logistik RDT yang diterima kader wajib diisi";
      isValid = false;
    }

    if (total_house_responsibility.value !== "") {
      if (total_house_visited_one_month.value === "") {
        total_house_visited_one_month.isValid = false;
        total_house_visited_one_month.message =
          "Total house visited one month is required";
        isValid = false;
      }

      if (total_house_visited_two_month.value === "") {
        total_house_visited_two_month.isValid = false;
        total_house_visited_two_month.message =
          "Total house visited two month is required";
        isValid = false;
      }

      if (total_house_visited.value === "") {
        total_house_visited.isValid = false;
        total_house_visited.message = "Total house visited is required";
        isValid = false;
      }
    }

    if (total_survei_target.value === "") {
      total_survei_target.isValid = false;
      total_survei_target.message = "Total survei target is required";
      isValid = false;
    }

    this.setState({
      month,
      year,
      logistic_receive,
      total_house_visited_one_month,
      total_house_visited_two_month,
      total_house_visited,
      total_cadre_inspection,
      total_suspect,
      total_survei_target,
      cadre_less_active_reason,
    });

    return isValid;
  };

  render() {
    return (
      <div className="dashboard-wrapper">
        <div
          className="my-3"
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.props.changeMode("view");
          }}
        >
          <ArrowLeft />
          <span>Kembali</span>
        </div>

        <div className="title font-weight-bold">
          Data kinerja kader endemis tinggi perbulan
        </div>
        <div className="content-wrapper mt-4">
          <div className="row">
            <div className="col-12">
              <div className="form-wrapper">
                <InputDateTime
                  label="Tahun"
                  name="year"
                  dateFormat="YYYY"
                  value={this.state.year.value}
                  placeholder="Tahun"
                  onChange={(e) => {
                    this.handleInputChange({
                      target: { name: "year", value: moment(e).format("YYYY") },
                    });
                  }}
                  isRequired={true}
                  hasError={!this.state.year.isValid}
                  errorMessage={this.state.year.message}
                  isDisabled={this.props.mode === PageMode.edit}
                />

                <InputSelect
                  label="Bulan"
                  name="month"
                  onChange={this.handleInputChange}
                  placeholder="Pilih bulan"
                  data={[
                    { label: "Januari", value: "01" },
                    { label: "Februari", value: "02" },
                    { label: "Maret", value: "03" },
                    { label: "April", value: "04" },
                    { label: "Mei", value: "05" },
                    { label: "Juni", value: "06" },
                    { label: "Juli", value: "07" },
                    { label: "Agustus", value: "08" },
                    { label: "September", value: "09" },
                    { label: "Oktober", value: "10" },
                    { label: "November", value: "11" },
                    { label: "Desember", value: "12" },
                  ]}
                  value={this.state.month.value}
                  isRequired={true}
                  hasError={!this.state.month.isValid}
                  errorMessage={this.state.month.message}
                  isDisabled={this.props.mode === PageMode.edit}
                />

                <InputField
                  label="Logistik RDT malaria yang diterima kader"
                  name="logistic_receive"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.logistic_receive.value}
                  placeholder="0"
                  isRequired={true}
                  hasError={!this.state.logistic_receive.isValid}
                  errorMessage={this.state.logistic_receive.message}
                />

                <div className="form-group">
                  <p className="font-weight-bold">
                    Wajib mengisi minimal satu isian (Target
                    Penemuan/Pemeriksaan atau Total Rumah yang menjadi tanggung
                    jawab kader)
                  </p>
                </div>

                <InputField
                  label="Target Penemuan / Pemeriksaan Oleh Kader"
                  name="target_cadre_inspection"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.target_cadre_inspection.value}
                  placeholder="0"
                />

                <InputField
                  label="Total Rumah yang menjadi Tanggung Jawab Kader"
                  name="total_house_responsibility"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_house_responsibility.value}
                  placeholder="0"
                />

                <InputField
                  label="Jumlah Rumah yang Dikunjungi satu kali dalam bulan ini"
                  name="total_house_visited_one_month"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_house_visited_one_month.value}
                  placeholder="0"
                  // isRequired={}
                  hasError={!this.state.total_house_visited_one_month.isValid}
                  errorMessage={
                    this.state.total_house_visited_one_month.message
                  }
                />

                <InputField
                  label="Jumlah Rumah yang Dikunjungi dua kali dalam bulan ini"
                  name="total_house_visited_two_month"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_house_visited_two_month.value}
                  placeholder="0"
                  // isRequired={}
                  hasError={!this.state.total_house_visited_two_month.isValid}
                  errorMessage={
                    this.state.total_house_visited_two_month.message
                  }
                />

                <div className="form-group row">
                  <label
                    htmlFor="total_house_visited"
                    className="col-sm-6 col-form-label"
                  >
                    Jumlah total kunjungan rumah
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="numberOnly"
                      className="form-control"
                      id="total_house_visited"
                      placeholder="0"
                      name="total_house_visited"
                      onChange={this.handleInputChange}
                      value={this.state.total_house_visited.value}
                      readOnly={true}
                    />
                    {
                      <div className="text-danger">
                        {this.state.total_house_visited.message}
                      </div>
                    }
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="total_cadre_inspection"
                    className="col-sm-6 col-form-label"
                  >
                    Jumlah pemeriksaan kader dalam bulan ini
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="numberOnly"
                      className="form-control"
                      id="total_cadre_inspection"
                      placeholder="0"
                      name="total_cadre_inspection"
                      onChange={this.handleInputChange}
                      value={this.state.total_cadre_inspection.value}
                      readOnly={true}
                    />
                    {
                      <div className="text-danger">
                        {this.state.total_cadre_inspection.message}
                      </div>
                    }
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="total_suspect"
                    className="col-sm-6 col-form-label"
                  >
                    Jumlah Suspek
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="numberOnly"
                      className="form-control"
                      id="total_suspect"
                      placeholder="0"
                      name="total_suspect"
                      onChange={this.handleInputChange}
                      value={this.state.total_suspect.value}
                      readOnly={true}
                    />
                    {
                      <div className="text-danger">
                        {this.state.total_suspect.message}
                      </div>
                    }
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="total_survei_target"
                    className="col-sm-6 col-form-label"
                  >
                    Jumlah sasaran survey kontak serumah
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="numberOnly"
                      className="form-control"
                      id="total_survei_target"
                      placeholder="0"
                      name="total_survei_target"
                      onChange={this.handleInputChange}
                      value={this.state.total_survei_target.value}
                    />
                    {
                      <div className="text-danger">
                        {this.state.total_survei_target.message}
                      </div>
                    }
                  </div>
                </div>

                <InputField
                  label="Keaktifan berdasarkan target kunjungan rumah (Otomatis)"
                  name="cadre_active"
                  type="text"
                  onChange={this.handleInputChange}
                  value={this.state.cadre_active.value}
                  placeholder="Status"
                  isReadOnly={true}
                />

                <InputSelect
                  label="Alasan kurang/tidak aktif berdasarkan kunjungan rumah"
                  name="cadre_less_active_reason"
                  onChange={this.handleInputChange}
                  placeholder="Pilih"
                  data={lessActiveReason}
                  value={this.state.cadre_less_active_reason.value}
                  isHidden={!this.state.showLessActiveReasonField}
                />

                <InputField
                  label="Lainnya"
                  name="other_less_active_reason"
                  onChange={this.handleInputChange}
                  placeholder="Pilih"
                  value={this.state.other_less_active_reason.value}
                  isHidden={
                    this.state.cadre_less_active_reason.value !== "Lainnya" ||
                    this.state.cadre_less_active_reason.value !== null
                  }
                />

                <InputField
                  label="Keaktifan berdasarkan target pemeriksaan (Otomatis)"
                  name="cadre_active_by_cadre_inspection"
                  type="text"
                  onChange={this.handleInputChange}
                  value={this.state.cadre_active_by_cadre_inspection.value}
                  placeholder="Status"
                  isReadOnly={true}
                />

                <InputSelect
                  label="Alasan kurang/tidak aktif berdasarkan target pemeriksaan"
                  name="cadre_less_active_reason_by_cadre_inspection"
                  onChange={this.handleInputChange}
                  placeholder="Pilih"
                  data={lessActiveReason}
                  value={
                    this.state.cadre_less_active_reason_by_cadre_inspection
                      .value
                  }
                  isHidden={
                    !this.state.showLessActiveReasonByCadreInspectionField
                  }
                />

                <InputField
                  label="Lainnya"
                  name="other_cadre_less_active_reason_by_cadre_inspection"
                  onChange={this.handleInputChange}
                  placeholder="Pilih"
                  value={
                    this.state
                      .other_cadre_less_active_reason_by_cadre_inspection.value
                  }
                  isHidden={
                    this.state.cadre_less_active_reason_by_cadre_inspection
                      .value !== "Lainnya" ||
                    this.state.cadre_less_active_reason_by_cadre_inspection
                      .value !== null
                  }
                />

                <div className="form-group d-flex justify-content-end">
                  <Button
                    onClick={() => this.props.changeMode("view")}
                    isSecondary
                  >
                    Batalkan
                  </Button>
                  <Button
                    onClick={this.onSave}
                    isPrimary
                    isDisabled={
                      this.state.isLoading ||
                      (!this.state.target_cadre_inspection.value &&
                        !this.state.total_house_responsibility.value)
                    }
                  >
                    {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
