const TOKEN_KEY = "x-token";
const CURRENT_USER_KEY = "current_user";

const PageMode = {
  view: "view",
  add: "add",
  edit: "edit",
  detail: "detail",
};

const Source = {
  COUNTRY: "country",
  PROVINCE: "province",
  DISTRICT: "district",
  SUBDISTRICT: "subdistrict",
  URBANVILLAGE: "urbanvillage",
  FASYANKES: "fasyankes",
};

const UserLevel = {
  Fasyankes: "Fasyankes",
  District: "District",
  Province: "Province",
  National: "National",
};

const requestStatus = {
  OK: 200,
  BadRequest: 400,
  Forbidden: 403,
  Conflict: 409,
  ServerError: 500,
};

const ErrorMessage = {
  UserNotVerified:
    "Akun Anda belum terverifikasi, mohon hubungi Administrator untuk pengecekan validasi data dasar atau verifikasi akun",
  UserFaskesNotHaveAccess:
    "Akun Anda belum terverifikasi, mohon hubungi Administrator untuk pengecekan validasi data dasar atau verifikasi akun",
  DataAlreadyExist: "Data yang anda inputkan sudah ada pada sistem",
  InternalServerError: "Terjadi kesalahan di sisi Server",
};

const UserRoles = {
  User: "User",
  Admin: "Admin",
  Viewer: "Viewer",
};

const CadreTupoksiOptions = [
  { label: "Penemuan Kasus", value: "Penemuan Kasus" },
  { label: "Promkes", value: "Promkes" },
  { label: "Surveilans", value: "Surveilans" },
  { label: "Pengendalian Vektor", value: "Pengendalian Vektor" },
  { label: "Lainnya", value: "Lainnya" },
];

const GenderOptions = [
  { label: "Laki Laki", value: "Laki Laki" },
  { label: "Perempuan", value: "Perempuan" },
];

const EducationOptions = [
  { label: "Tidak Tamat SD", value: "Tidak Tamat SD" },
  { label: "Tamat SD", value: "Tamat SD" },
  { label: "SMP", value: "SMP" },
  { label: "SMA", value: "SMA" },
  { label: "Diploma", value: "Diploma" },
  { label: "Sarjana", value: "Sarjana" },
  { label: "Magister", value: "Magister" },
  { label: "Doktor", value: "Doktor" },
];

const OAPOptions = [
  { label: "Orang Asli Papua (OAP)", value: "Orang Asli Papua (OAP)" },
  { label: "Bukan Orang Asli Papua", value: "Bukan Orang Asli Papua" },
];

const YesAndNoOptions = [
  { label: "Ya", value: "Ya" },
  { label: "Tidak", value: "Tidak" },
];

const FundSourceCadreOptions = [
  { label: "Global Fund", value: "Global Fund" },
  { label: "APBD", value: "APBD" },
  { label: "Dana Kampung", value: "Dana Kampung" },
  { label: "Lainnya", value: "Lainnya" },
];

const CadreOriginOptions = [
  { label: "Bukan Perdhaki", value: "Bukan Perdhaki" },
  { label: "Perdhaki", value: "Perdhaki" },
];

const MmpTypeOptions = [
  { label: "Pekerja tambang", value: "Pekerja tambang" },
  { label: "Pekerja hutan", value: "Pekerja hutan" },
  { label: "Pekerja perkebunan", value: "Pekerja perkebunan" },
  {
    label: "Indigenous people/Suku pedalaman/KAT",
    value: "Indigenous people/Suku pedalaman/KAT",
  },
  {
    label: "Pendatang Sementara (TNI, POLRI, Pekerja Migran, dan lainnya)",
    value: "Pendatang Sementara (TNI, POLRI, Pekerja Migran, dan lainnya)",
  },
];

const MonthOptions = [
  { label: "Januari", value: "01" },
  { label: "Februari", value: "02" },
  { label: "Maret", value: "03" },
  { label: "April", value: "04" },
  { label: "Mei", value: "05" },
  { label: "Juni", value: "06" },
  { label: "Juli", value: "07" },
  { label: "Agustus", value: "08" },
  { label: "September", value: "09" },
  { label: "Oktober", value: "10" },
  { label: "November", value: "11" },
  { label: "Desember", value: "12" },
];

const CaseFromOptions = [
  { value: "Faskes Pencatat", label: "Faskes Pencatat" },
  { value: "Pustu", label: "Pustu" },
  { value: "Klinik/RS Swasta", label: "Klinik/RS Swasta" },
  {
    value: "DPM/Praktek Bidan/Perawat",
    label: "DPM/Praktek Bidan/Perawat",
  },
  { value: "KKP", label: "KKP" },
  { value: "Klinik Perdhaki", label: "Klinik Perdhaki" },
  { value: "Pos Kesehatan TNI", label: "Pos Kesehatan TNI" },
  { value: "Lainnya", label: "Lainnya" },
];

const OperatorOptions = [
  { label: "Nakes", value: "Nakes" },
  { label: "Kader", value: "Kader" },
];

const PregnancyStatusOptions = [
  { label: "Hamil", value: "Hamil" },
  { label: "Tidak Hamil", value: "Tidak Hamil" },
];

const AgeTypeOptions = [
  { label: "Tahun", value: "Tahun" },
  { label: "Bulan", value: "Bulan" },
];

const JobsListOptions = [
  { value: "Nelayan", label: "Nelayan" },
  { value: "Petani", label: "Petani" },
  { value: "Buruh Tambang", label: "Buruh Tambang" },
  { value: "Ibu Rumah Tangga", label: "Ibu Rumah Tangga" },
  { value: "Pegawai", label: "Pegawai" },
  { value: "TNI", label: "TNI" },
  { value: "POLRI", label: "POLRI" },
  { value: "Berkebun", label: "Berkebun" },
  { value: "Perambah Hutan", label: "Perambah Hutan" },
  { value: "Pedagang", label: "Pedagang" },
  { value: "Pelajar", label: "Pelajar" },
  { value: "Tidak Bekerja", label: "Tidak Bekerja" },
  { value: "Lainnya", label: "Lainnya" },
];

const SymthomaticOptions = [
  { value: "Demam", label: "Demam" },
  { value: "Lainnya", label: "Gejala Lainnya" },
  { value: "Tidak bergejala", label: "Tidak bergejala" },
];

const StandarMedicationReasonOptions = [
  { value: "Tidak tersedia obat", label: "Tidak tersedia obat" },
  { value: "Pasien menolak diobati", label: "Pasien menolak diobati" },
  { value: "Pasien dirujuk", label: "Pasien dirujuk" },
  { value: "Lainnya", label: "Lainnya" },
];

const RegmalCaseFromOptions = [
  { value: "Faskes Pencatat", label: "Faskes Pencatat" },
  { value: "Pustu", label: "Pustu" },
  { value: "Klinik/RS Swasta", label: "Klinik/RS Swasta" },
  {
    value: "DPM/Praktek Bidan/Perawat",
    label: "DPM/Praktek Bidan/Perawat",
  },
  { value: "KKP", label: "KKP" },
  { value: "Klinik Perdhaki", label: "Klinik Perdhaki" },
  { value: "Pos Kesehatan TNI", label: "Pos Kesehatan TNI" },
  { value: "Lainnya", label: "Lainnya" },
];

const LabCheckingOptions = [
  { value: "Mikroskop", label: "Mikroskop" },
  { value: "RDT", label: "RDT" },
  { value: "Tanpa Pemeriksaan", label: "Tanpa Pemeriksaan" },
];

const LabCheckingOptionsRujukan = [
  { value: "Mikroskop", label: "Mikroskop" },
  { value: "RDT", label: "RDT" },
];

const DiseaseDegreeOptions = [
  { value: "Tanpa Komplikasi", label: "Tanpa Komplikasi" },
  {
    value: "Komplikasi (Malaria Berat)",
    label: "Komplikasi (Malaria Berat)",
  },
];

const GametositOptions = [
  { value: "Positif", label: "Positif" },
  { value: "Negatif", label: "Negatif" },
];

const MedicationStatusOptions = [
  { label: "Standard", value: "Standard" },
  { label: "Tidak Standard", value: "Tidak Standard" },
  { label: "Presumtif", value: "Presumtif" },
];

const TreatmentOptions = [
  { value: "Rawat Jalan", label: "Rawat Jalan" },
  { value: "Rawat Inap", label: "Rawat Inap" },
];

const ParasyteOptions = [
  { label: "P.Falciparum", value: "P.Falciparum" },
  { label: "P.Malariae", value: "P.Malariae" },
  { label: "Probable Knowlesi", value: "Probable Knowlesi" },
  { label: "P.Vivax", value: "P.Vivax" },
  { label: "P.Ovale", value: "P.Ovale" },
  { label: "P.Mix", value: "P.Mix" },
];

const RecurrenceOptions = [
  { label: "Baru", value: "Baru" },
  { label: "Relaps", value: "Relaps" },
];

const LogisticInputStockOptions = [
  { value: "DHP Dispersible", label: "DHP Dispersible" },
  { value: "DHP (Tablet)", label: "DHP (Tablet)" },
  { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
  { value: "Artesunat Inj (Vial)", label: "Artesunat Inj (Vial) " },
  { value: "Kina (Tablet)", label: "Kina (Tablet)" },
  { value: "Kina Inj (Ampul)", label: "Kina Inj (Ampul)" },
  { value: "Doksisiklin (Tablet)", label: "Doksisiklin (Tablet)" },
  { value: "RDT (Test)", label: "RDT (Test)" },
  { value: "LLIN (Lembar)", label: "LLIN (Lembar)" },
  { value: "Insektisida (Kg)", label: "Insektisida (Kg)" },
  { value: "Insektisida (Liter)", label: "Insektisida (Liter)" },
  { value: "Larvasida (Kg)", label: "Larvasida (Kg)" },
  { value: "Larvasida (Liter)", label: "Larvasida (Liter)" },
  { value: "Lab Kit (Paket)", label: "Lab Kit (Paket)" },
  { value: "Giemsa (CC)", label: "Giemsa (CC)" },
  { value: "Minyak Imersi (CC)", label: "Minyak Imersi (CC)" },
  { value: "Blood Slide (Box)", label: "Blood Slide (Box)" },
  { value: "Slide Box (Buah)", label: "Slide Box (Buah)" },
  { value: "Blood Lancet (Box)", label: "Blood Lancet (Box)" },
  { value: "Methanol (CC)", label: "Methanol (CC)" },
  { value: "Spray Can (Buah)", label: "Spray Can (Buah)" },
  { value: "Mist Blower (Buah)", label: "Mist Blower (Buah)" },
  {
    value: "Microscope Binocular (Buah)",
    label: "Microscope Binocular (Buah)",
  },
  {
    value: "Microscope Trinocular (Buah)",
    label: "Microscope Trinocular (Buah)",
  },
  {
    value: "Microscope Teaching (Buah)",
    label: "Microscope Teaching (Buah)",
  },
];

const LogisticInputStockOptionsNonPKM = [
  { value: "DHP Dispersible", label: "DHP Dispersible" },
  { value: "DHP (Tablet)", label: "DHP (Tablet)" },
  { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
  { value: "Artesunat Inj (Vial)", label: "Artesunat Inj (Vial) " },
  { value: "Kina (Tablet)", label: "Kina (Tablet)" },
  { value: "Kina Inj (Ampul)", label: "Kina Inj (Ampul)" },
  { value: "Doksisiklin (Tablet)", label: "Doksisiklin (Tablet)" },
  { value: "RDT (Test)", label: "RDT (Test)" },
  { value: "Lab Kit (Paket)", label: "Lab Kit (Paket)" },
  { value: "Giemsa (CC)", label: "Giemsa (CC)" },
  { value: "Minyak Imersi (CC)", label: "Minyak Imersi (CC)" },
  { value: "Blood Slide (Box)", label: "Blood Slide (Box)" },
  { value: "Slide Box (Buah)", label: "Slide Box (Buah)" },
  { value: "Blood Lancet (Box)", label: "Blood Lancet (Box)" },
  { value: "Methanol (CC)", label: "Methanol (CC)" },
  {
    value: "Microscope Binocular (Buah)",
    label: "Microscope Binocular (Buah)",
  },
];

const TypeBreedingPlaceOptions = [
  { label: "Kolam", value: "Kolam" },
  { label: "Sungai", value: "Sungai" },
  { label: "Sawah", value: "Sawah" },
  { label: "Lagun", value: "Lagun" },
  { label: "Parit", value: "Parit" },
  { label: "Bekas Galian", value: "Bekas Galian" },
  { label: "Rawa", value: "Rawa" },
  { label: "Bekas Tambak", value: "Bekas Tambak" },
  { label: "Bekas Injakan Hewan", value: "Bekas Injakan Hewan" },
  { label: "Lainnya", value: "Lainnya" },
];

const TraitBreedingOptions = [
  { label: "Permanen", value: "Permanen" },
  { label: "Sementara", value: "Sementara" },
];

const LarvacideTypeOptions = [
  { label: "Biolarvasida", value: "Biolarvasida" },
  {
    label: "IGR/Juvenile Hormone",
    value: "IGR/Juvenile Hormone",
  },
  { label: "Organophosphate", value: "Organophosphate" },
  { label: "BenzoylUreas", value: "BenzoylUreas" },
];

const UnitOptions = [
  { label: "Kg", value: "Kg" },
  { label: "Liter", value: "Liter" },
];

const SpeciesMosquitoOptions = [
  {
    label: "An. barbirostris",
    value: "An. barbirostris",
    variable: "barbirostris",
  },
  { label: "An. aconitus", value: "An. aconitus", variable: "aconitus" },
  { label: "An. bancrofti", value: "An. bancrofti", variable: "bancrofti" },
  {
    label: "An. balabacensis",
    value: "An. balabacensis",
    variable: "balabacensis",
  },
  { label: "An. farauti", value: "An. farauti", variable: "farauti" },
  {
    label: "An. flavirostris",
    value: "An. flavirostris",
    variable: "flavirostris",
  },
  { label: "An. koliensis", value: "An. koliensis", variable: "koliensis" },
  { label: "An. letifer", value: "An. letifer", variable: "letifer" },
  { label: "An. lodiowae", value: "An. lodiowae", variable: "lodiowae" },
  { label: "An. maculatus", value: "An. maculatus", variable: "maculatus" },
  { label: "An. minimus", value: "An. minimus", variable: "minimus" },
  {
    label: "An. punctulatus",
    value: "An. punctulatus",
    variable: "punctulatus",
  },
  { label: "An. nigerrimus", value: "An. nigerrimus", variable: "nigerrimus" },
  { label: "An. sinensis", value: "An. sinensis", variable: "sinensis" },
  { label: "An. subpictus", value: "An. subpictus", variable: "subpictus" },
  { label: "An. sundaicus", value: "An. sundaicus", variable: "sundaicus" },
  { label: "An. annularis", value: "An. annularis", variable: "annularis" },
  {
    label: "An. barbumbrosus",
    value: "An. barbumbrosus",
    variable: "barbumbrosus",
  },
  { label: "An. karwari", value: "An. karwari", variable: "karwari" },
  { label: "An. kochi", value: "An. kochi", variable: "kochi" },
  {
    label: "An. leucosphyrus",
    value: "An. leucosphyrus",
    variable: "leucosphyrus",
  },
  {
    label: "An. parangensis",
    value: "An. parangensis",
    variable: "parangensis",
  },
  { label: "An. umbrosus", value: "An. umbrosus", variable: "umbrosus" },
  { label: "An. vagus", value: "An. vagus", variable: "vagus" },
  { label: "An. tessalatus", value: "An. tessalatus", variable: "tessalatus" },
  {
    label: "An. peditaeniatus",
    value: "An. peditaeniatus",
    variable: "peditaeniatus",
  },
  {
    label: "Anopheles Lainnya",
    value: "Anopheles Lainnya",
    variable: null,
  },
];

const indicatorAnalysisOptions = [
  {
    label: "Jumlah Kasus Positif",
    value: "indikator_2",
  },
  {
    label: "Jumlah Kasus Kematian",
    value: "indikator_20",
  },
  {
    label: "Jumlah Pemeriksaan",
    value: "indikator_7",
  },
  {
    label: "Jumlah Kasus Indegenous",
    value: "indikator_13",
  },
  {
    label: "Annual Parasite Incidence (API)",
    value: "indikator_24",
  },
  {
    label: "Annual Blood Examination Rate (ABER)",
    value: "indikator_5",
  },
  {
    label: "% Kelengkapan Laporan",
    value: "indikator_1",
  },
  {
    label: "% Konfirmasi Laboratorium Malaria",
    value: "indikator_4",
  },
  {
    label: "% Pengobatan Standar Malaria",
    value: "indikator_11",
  },
  {
    label: "% Positivity Rate Malaria",
    value: "indikator_10",
  },
  {
    label: "% Notifikasi Kasus Positif yang Dilakukan PE",
    value: "indikator_15",
  },
  {
    label: "% Ketepatan PE",
    value: "indikator_25",
  },
  {
    label: "% Kasus Indegenous yang Tervalidasi",
    value: "indikator_6",
  },
  {
    label: "% Kelengkapan Laporan Logistik Malaria",
    value: "indikator_14",
  },
  {
    label: "% Faskes yang Melapor Tidak Putus Stok",
    value: "indikator_19",
  },
  {
    label: "% Ketepatan Laporan (Wilayah dengan API < 1)",
    value: "indikator_9",
  },
  {
    label: "% Ketepatan Laporan (Wilayah dengan API > 1)",
    value: "indikator_8",
  },
  {
    label: "% Keaktifan Kinerja Kader",
    value: "indikator_23",
  },
  {
    label: "Tren Kasus Kematian",
    value: "indikator_3",
  },
  {
    label: "Tren SKD Malaria",
    value: "indikator_16",
  },
  {
    label: "Proporsi Jenis Plasmodium",
    value: "indikator_12",
  },
  {
    label: "Proporsi Berdasarkan Penemuan Kasus",
    value: "indikator_17",
  },
  {
    label: "Proporsi Kasus Positif Berdasarkan Kepemilikan",
    value: "indikator_18",
  },
  {
    label: "Proporsi Penemuan Kasus berdasarkan Jenis Petugas",
    value: "indikator_21",
  },
  {
    label: "Proporsi berdasarkan Klasifikasi Kasus",
    value: "indikator_22",
  },
  {
    label: "Proporsi Berdasarkan Jenis Mobile Migrant Population (MMP)",
    value: "indikator_27",
  },
];

export {
  TOKEN_KEY,
  CURRENT_USER_KEY,
  PageMode,
  Source,
  UserLevel,
  ErrorMessage,
  UserRoles,
  requestStatus,
  CadreTupoksiOptions,
  GenderOptions,
  EducationOptions,
  OAPOptions,
  YesAndNoOptions,
  FundSourceCadreOptions,
  CadreOriginOptions,
  MmpTypeOptions,
  MonthOptions,
  CaseFromOptions,
  OperatorOptions,
  PregnancyStatusOptions,
  JobsListOptions,
  SymthomaticOptions,
  StandarMedicationReasonOptions,
  RegmalCaseFromOptions,
  LabCheckingOptions,
  DiseaseDegreeOptions,
  GametositOptions,
  MedicationStatusOptions,
  TreatmentOptions,
  ParasyteOptions,
  RecurrenceOptions,
  LogisticInputStockOptions,
  LogisticInputStockOptionsNonPKM,
  LabCheckingOptionsRujukan,
  AgeTypeOptions,
  TypeBreedingPlaceOptions,
  TraitBreedingOptions,
  LarvacideTypeOptions,
  UnitOptions,
  SpeciesMosquitoOptions,
  indicatorAnalysisOptions,
};
