import moment from "moment";
import React, { Component } from "react";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import PeKasus from "services/PeKasus";

const PeKasusService = new PeKasus();

export default class IndigenousDetail extends Component {
  _isMounted = true;

  state = {
    data: {},
  };

  componentDidMount() {
    this._isMounted = true;
    this.getIndigenousData();
  }

  getIndigenousData = async () => {
    await PeKasusService.getOne(this.props.match.params.id)
      .then((res) => {
        this.setState({
          data: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="dashboard-wrapper">
        <Link
          to="/validasi-indigenous"
          style={{
            color: "black",
            textDecoration: "none",
          }}
        >
          <ArrowLeft />
          <span>Kembali</span>
        </Link>

        <div className="row mt-3">
          <div className="col-sm-12">
            <h1 className="title font-weight-bold">Detail Data Indigenous</h1>
            <hr />
            <table className="table table-striped">
              <tr>
                <th>Alamat</th>
                <td>:</td>
                <td>{this.state.data.address}</td>
              </tr>
              <tr>
                <th>Alamat Asal Penularan</th>
                <th>:</th>
                <td>{this.state.data.address_of_infection}</td>
              </tr>
              <tr>
                <th>Tanggal Wawancara Kasus</th>
                <th>:</th>
                <td>{this.state.data.case_interview_date}</td>
              </tr>
              <tr>
                <th>Kontak Survey</th>
                <th>:</th>
                <td>{this.state.data.contact_survey}</td>
              </tr>
              <tr>
                <th>Tanggal Survey Kontak</th>
                <th>:</th>
                <td>
                  {this.state.data.contact_survey_date ? moment(this.state.data.contact_survey_date).format(
                    "YYYY-MM-DD"
                  ) : ""}
                </td>
              </tr>
              <tr>
                <th>Titik Koordinat Penularan</th>
                <th>:</th>
                <td>{this.state.data.coordinate}</td>
              </tr>
              <tr>
                <th>Negara Asal Penularan</th>
                <th>:</th>
                <td>{this.state.data.country_origin}</td>
              </tr>
              <tr>
                <th>Provinsi Pencatat</th>
                <th>:</th>
                <td>{this.state.data.regmal?.province_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Kab/Kota Pencatat</th>
                <th>:</th>
                <td>{this.state.data.regmal?.district_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Kecamatan Pencatat</th>
                <th>:</th>
                <td>{this.state.data.regmal?.subdistrict_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Faskes Pencatat</th>
                <th>:</th>
                <td>{this.state.data.regmal?.fasyankes_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Provinsi Yang Melakukan PE</th>
                <th>:</th>
                <td>{this.state.data.province_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Kab/Kota Yang Melakukan PE</th>
                <th>:</th>
                <td>{this.state.data.district_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Kecamatan Yang Melakukan PE</th>
                <th>:</th>
                <td>{this.state.data.subdistrict_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Faskes Yang Melakukan PE</th>
                <th>:</th>
                <td>{this.state.data.fasyankes_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Desa Yang Melakukan PE</th>
                <th>:</th>
                <td>{this.state.data.urbanvillage_id?.split("|")[1]}</td>
              </tr>
              <tr>
                <th>Identifikasi Penularan Nyamuk</th>
                <th>:</th>
                <td>{this.state.data.identify_mosquito_breeding_area}</td>
              </tr>
              <tr>
                <th>Tanggal Identifikasi Penularan Nyamuk</th>
                <th>:</th>
                <td>
                  {this.state.data.identify_mosquito_breeding_area_date ? moment(
                    this.state.data.identify_mosquito_breeding_area_date
                  ).format("YYYY-MM-DD") : ""}
                </td>
              </tr>
              <tr>
                <th>Indigenous</th>
                <th>:</th>
                <td>{this.state.data.indigenous}</td>
              </tr>
              <tr>
                <th>Keterangan</th>
                <th>:</th>
                <td>{this.state.data.indigenous_desc}</td>
              </tr>
              <tr>
                <th>Status Indigenous</th>
                <th>:</th>
                <td>{this.state.data.indigenous_status}</td>
              </tr>
              <tr>
                <th>Tanggal Kasus Awal</th>
                <th>:</th>
                <td>
                  {this.state.data.initial_case_date ? moment(this.state.data.initial_case_date).format(
                    "YYYY-MM-DD"
                  ) : ""}
                </td>
              </tr>
              <tr>
                <th>Kasus Pada MMP</th>
                <th>:</th>
                <td>{this.state.data.mmp_case}</td>
              </tr>
              <tr>
                <th>ID MMP</th>
                <th>:</th>
                <td>{this.state.data.mmp_id}</td>
              </tr>
              <tr>
                <th>Nama Pasien</th>
                <th>:</th>
                <td>{this.state.data.regmal && this.state.data.regmal.name}</td>
              </tr>
              <tr>
                <th>Nomor Telepon</th>
                <th>:</th>
                <td>{this.state.data.phone_number}</td>
              </tr>
              <tr>
                <th>Kekambuhan</th>
                <th>:</th>
                <td>{this.state.data.recurrence}</td>
              </tr>
              <tr>
                <th>Nama Dusun</th>
                <th>:</th>
                <td>{this.state.data.sub_village}</td>
              </tr>
              <tr>
                <th>Jumlah Pemeriksaan Mikroskopis di atas 5 tahun</th>
                <th>:</th>
                <td>
                  {this.state.data.total_microscopic_inspection_over_five_year}
                </td>
              </tr>
              <tr>
                <th>Jumlah Pemeriksaan Mikroskopis dibawah 5 tahun</th>
                <th>:</th>
                <td>
                  {this.state.data.total_microscopic_inspection_under_five_year}
                </td>
              </tr>
              <tr>
                <th>Jumlah Pemeriksaan RDT di atas 5 Tahun</th>
                <th>:</th>
                <td>{this.state.data.total_rdt_inspection_over_five_year}</td>
              </tr>
              <tr>
                <th>Jumlah Pemeriksaan RDT dibawah 5 Tahun</th>
                <th>:</th>
                <td>{this.state.data.total_rdt_inspection_under_five_year}</td>
              </tr>
              <tr>
                <th>Penularan</th>
                <th>:</th>
                <td>{this.state.data.transmission}</td>
              </tr>
              <tr>
                <th>Asal Penularan</th>
                <th>:</th>
                <td>{this.state.data.transmission_origin}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
