import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
// Retrieve all Leaflet Default Icon options from CSS, in particular all icon images URL's, to improve compatibility with bundlers and frameworks that modify URL's in CSS.
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";

import L from 'leaflet';

import "./Map.scss";

const MyMap = ({ geoJson, indicator }) => {
  const geoJsonLayer = useRef();
  const [hoveredInfo, setHoveredInfo] = useState(null);
  const center = [-2.6691934678429545, 116.40094206247522];

  const style = useCallback((feature) => {
    return {
      fillColor: feature.properties.color,
      weight: 1,
      opacity: 1,
      color: '#666666',
      dashArray: '1',
      fillOpacity: 0.7
    };
  }, []);

  const highlightFeature = useCallback((e) => {
    const layer = e.target;

    layer.setStyle({
      weight: 3,
      color: '#002afc',
      dashArray: '',
      fillOpacity: 0.9
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }

    setHoveredInfo(layer.feature.properties);
  }, []);

  const resetHighlight = useCallback((e) => {
    if (geoJsonLayer.current) {
      geoJsonLayer.current.resetStyle(e.target);
    }

    setHoveredInfo(null);
  }, []);

  const legendData = geoJson.features.reduce((acc, r) => {
    if (indicator === "indikator_26") {
      if (!acc.some(item => item.value === r.properties.status)) {
        acc.push({ value: r.properties.status, color: r.properties.color, api: r.properties.api });
      }
    } else if (indicator === "indikator_28" || indicator === "indikator_29") {
      if (!acc.some(item => item.value === r.properties.status)) {
        acc.push({ value: r.properties.status, color: r.properties.color });
      }
    }
    return acc;
  }, []);

  const onEachFeature = useCallback((feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight
    });

    // Add static label using Tooltip
    const tooltipContent = indicator === "indikator_26"
      ? `${feature.properties.district_name || 'N/A'}`
      : `Desa: ${feature.properties.village_name || 'N/A'}`;

    // Create the tooltip but do not make it permanent initially
    const tooltip = layer.bindTooltip(tooltipContent, {
      permanent: false,
      direction: "center",
      className: "static-tooltip",
    });


    const controlTooltipVisibility = () => {
      const currentZoom = layer._map.getZoom();
      const minZoomForTooltip = 8;

      if (currentZoom >= minZoomForTooltip) {
        tooltip.setTooltipContent(tooltipContent);
        tooltip.options.permanent = true;
        tooltip.openTooltip();
      } else {
        tooltip.options.permanent = false;
        tooltip.closeTooltip();
      }
    };

    if (indicator === "indikator_26") {
      layer.on('add', () => {
        const map = layer._map;
        if (map) {
          map.on('zoomend', controlTooltipVisibility);
          controlTooltipVisibility();
        }
      });
    }

  }, [highlightFeature, resetHighlight, indicator]);

  return (
    <>
      <MapContainer center={center}
        zoom={4}
        scrollWheelZoom={true}
        style={{ height: "400px" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {geoJson && <GeoJSON data={geoJson} style={style} onEachFeature={onEachFeature} ref={geoJsonLayer} />}
        <div className="info-box" style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '10px',
          backgroundColor: 'white',
          opacity: 0.7,
          border: '1px solid #ccc',
          borderRadius: '5px',
          zIndex: 1000
        }}>
          {hoveredInfo ? (
            <div>
              <p>
                <span>
                  <b>PROVINSI</b>: {hoveredInfo.province_name}
                </span>
                <br />
                <span>
                  <b>KAB/KOTA</b>: {hoveredInfo.district_name}
                </span>
                <br />
                {indicator === "indikator_26" && (
                  <span>
                    <b>API: </b>{hoveredInfo.api}
                  </span>
                )}
                {(indicator === "indikator_28" || indicator === "indikator_29") && (
                  <span>
                    <b>NAMA DESA: </b>{hoveredInfo.village_name}
                  </span>
                )}
                <br />
                <span>
                  <b>Status: </b>{hoveredInfo.status}
                </span>
              </p>
            </div>
          ) : (
            <div>Hover over a region</div>
          )}
        </div>
      </MapContainer >

      {/* legend here */}
      <div className="legend-wrapper">
        <h2>Legend:</h2>
        <div className="legends">
          {legendData
            .sort((a, b) => a.value.localeCompare(b.value))
            .map((item, index) => (
              <div key={index} className="legend-item">
                <span
                  className="legend-color"
                  style={{ backgroundColor: item.color }}
                ></span>
                <span className="legend-label">{item.value == "NA" ? "NA Tidak Teridentifikasi" : item.value || '-'}</span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default MyMap;
